import React from 'react';
import _ from 'lodash';
import { Modal, Typography } from 'antd';
import BorderButton from './BorderButton';

const { Title } = Typography;

const InfoModal = (props) => {
  const { open, modalType, handleClose } = props;

  const renderModalText = () => {
    switch (modalType) {
      case 'notEligible':
        return 'You are not eligible for this program. Please visit our Upcoming Events page for other sessions that may interested you.';
      case 'maxCapacity':
        return 'Unfortunately, this program is at capacity. Please visit the Upcoming Events Page for another program that may interest you.';
      case 'maxAttendedMeetings':
        return 'You have reached the maximum number of programs on this topic that you may attend in a 12-month period and are unable to register for this program.';
      default:
        return 'Something went wrong. Please try again later.';
    }
  };

  return (
    <Modal
      key={'regconfirmmodal'}
      visible={open}
      onOk={handleClose}
      onCancel={handleClose}
      destroyOnClose
      footer={null}
      centered
      // width={'80%'}
      style={{ padding: 'auto', top: 10, textAlign: 'center' }}
    >
      <div style={{ padding: 25 }}>
        <Title style={{ color: '#04799e', fontSize: 22, marginBottom: 30 }}>
          {renderModalText()}
        </Title>
        <BorderButton
          text={_.includes(['notEligible', 'maxCapacity'], modalType) ? 'Upcoming Events' : 'Close'}
          onClick={handleClose}
          wrapperStyle={{ maxWidth: 200, margin: 'auto', marginBottom: 8 }}
        />
      </div>
    </Modal>
  );
};

export default InfoModal;
