/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable global-require */
import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import ReactGA from 'react-ga4';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Typography, Divider, Row, Col, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
// import ReactGA from 'react-ga4';
import { SessionCard, InterestTopics, InfoModal, ITCPolicy, ITCBannerCarousel } from '../common';
import TopSessionComponent from './TopSessionComponent';
// import BorderButton from '../common/BorderButton';
import styles from '../../Styles/Home.module.scss';
import { getSessions } from '../../redux/dataSlice';
import GreenBox from '../common/GreenBox';
// import config from '../../config';
// import RegisterBanner from '../common/RegisterBanner';
import TopGameBanner from '../common/TopGameBanner';
// import Item from 'antd/lib/list/Item';
// import degreeTitles from './degreeTitles.json';

// const img = require('../../img/jumbotron-primary.svg');

const { Title, Text } = Typography;

const Home = (props) => {
  const { sessions, mySessions, user, loading } = props;

  // const [isMounted, setIsMounted] = React.useState(false);
  const [upcomingSessions, setUpcomingSessions] = React.useState([]);
  const [homeSessionsLoaded, setHomeSessionsLoaded] = React.useState(false);

  const [infoModalOpen, setInfoModalOpen] = React.useState(false);
  const [infoModalType, setInfoModalType] = React.useState(null);

  React.useEffect(() => {
    return () => {
      // get new sessions when this component unmounts
      // console.log('getting more sessions');
      getSessions();
      setUpcomingSessions([]);
      setHomeSessionsLoaded(false);
    };
  }, []);

  React.useEffect(() => {
    // all sessions in my sessions
    // const actualMysessions = _.filter(sessions, (session) => {
    //   return _.includes(mySessions, session.id);
    // });
    // // exclude broadcasts
    // const filteredMySessions = _.filter(actualMysessions, (session) => {
    //   return !session.is_broadcast;
    // });

    // const old_deck_cap_reched =
    //   _.filter(filteredMySessions, (session) => session.meetingtitle < 12).length >= 3;

    // const new_deck_cap_reched =
    //   _.filter(filteredMySessions, (session) => session.meetingtitle >= 12).length >= 3;

    // console.log(
    //   'old_deck_cap_reched',
    //   old_deck_cap_reched,
    //   'new_deck_cap_reched',
    //   new_deck_cap_reched
    // );

    // if (filteredMySessions.length >= 3 && upcomingSessions.length > 0) {
    //   setUpcomingSessions([]);
    // } else if {
    if (
      // filteredMySessions.length < 3 &&
      sessions.length &&
      !homeSessionsLoaded &&
      !upcomingSessions.length
    ) {
      const now = moment(new Date()).format('YYYY-MM-DDTHH:mm:ss');
      const upcoming = _.filter(sessions, (session) => {
        // if (session.meetingtitle < 12 && old_deck_cap_reched) {
        //   return false;
        // }
        // if (session.meetingtitle >= 12 && new_deck_cap_reched) {
        //   return false;
        // }

        const includeSession =
          !_.includes(mySessions, session.id) &&
          session.startdatetime >= now &&
          (!session.is_broadcast || session.clientprogram === 'Centralized Speaker Program');

        return includeSession;
      });
      setUpcomingSessions(upcoming);
      setHomeSessionsLoaded(true);
    }
  }, [sessions, mySessions, upcomingSessions, homeSessionsLoaded]);

  const toggleInfoModal = (statusCode) => {
    setInfoModalOpen(!infoModalOpen);
    let modalType = statusCode === 401 ? 'maxAttendedMeetings' : 'notEligible';
    if (statusCode === 406) {
      modalType = 'maxCapacity';
    }
    setInfoModalType(infoModalOpen ? null : modalType);
    if (infoModalOpen) {
      ReactGA.event({
        category: `InfoModal: ${infoModalType}`,
        action: 'Close'
      });
      if (_.includes(['notEligible', 'maxCapacity'], infoModalType)) {
        window.location.href = '/upcoming-events';
      }
    }
  };

  const renderUpcomingSessions = () => {
    console.log(upcomingSessions);
    return (
      <div className={`${styles['upcoming-sessions']}`}>
        <div className={`${styles['bg-layer']} ${styles.secondary}`}>
          <Title style={{ margin: '10px auto 30px auto', color: '#00597d' }} level={2}>
            Attend an Upcoming Session
          </Title>
          {/* <RegisterBanner /> */}
          <div
            className={`${styles.flex} ${styles['mobile-column']} direction-column`}
            style={{ justifyContent: 'center', width: '100%', margin: '0 auto' }}
          >
            <Row className={`${styles['ant-row']} ${styles['ie-show-session']}`}>
              {upcomingSessions.length ? (
                _.slice(upcomingSessions, 0, 2).map((session) => {
                  return (
                    <Col
                      className={`${styles['ant-col']} ${styles['ie-show-session']}`}
                      key={session.id}
                    >
                      <SessionCard
                        altLayout
                        key={`${session.id}`}
                        session={session}
                        toggleInfoModal={toggleInfoModal}
                        style={{ width: '100%', maxWidth: 800 }}
                      />
                    </Col>
                  );
                })
              ) : (
                <Col className={`${styles['ant-col']}`}>
                  <Text>Upcoming sessions will be listed here as they become available</Text>
                </Col>
              )}
            </Row>
          </div>
          {/* <ITCPolicy /> */}
        </div>
      </div>
    );
  };

  // const isDr = (degrees) => {
  //   // TODO
  //   // review with exit at first find instead of looping over all degrees everytime
  //   // return _.some(degrees, (degree) => {
  //   //   return config.drTitles.indexOf(degree) > -1;
  //   // });
  //   return _.some(degrees, (degree) => {
  //     return _.includes(config.drTitles, degree);
  //   });
  // };

  return (
    <div className={`${styles['home-page']}`}>
      <GreenBox />

      <div className="wrapper padding">
        <div className={`${styles['bg-layer']} ${styles.primary}`} />
        <TopGameBanner />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            margin: '0 auto',
            position: 'relative',
            top: 0
          }}
        >
          <Divider style={{ height: 50, border: 0 }} type="vertical" />

          <div style={{ width: '100%', maxWidth: 1200, margin: '0 auto', textAlign: 'left' }}>
            {/* keep this wrapper div or spacing gets messed up */}
            <Title
              className="text__white"
              style={{ marginBottom: 0, fontFamily: 'Roboto-Bold' }}
              level={3}
            >
              Welcome, {user.is_dr ? 'Dr.' : ''} {user.first_name} {user.last_name}
            </Title>
            <Title style={{ marginTop: 0, marginBottom: 0 }} className="text__white">
              Intra-Cellular Therapies Psych Center
            </Title>
            <Text
              style={{ marginTop: 0, marginBottom: 0, fontFamily: 'Roboto-Medium' }}
              className="text__white"
            >
              Thank you for visiting our promotional speaker program portal. We hope you enjoy
              everything the Intra-Cellular Therapies Psych Center provides.
            </Text>
          </div>
          <Divider style={{ height: 50, border: 0 }} type="vertical" />
          <TopSessionComponent />
          <Divider style={{ height: 50 }} type="vertical" />
        </div>
      </div>
      <InfoModal handleClose={toggleInfoModal} modalType={infoModalType} open={infoModalOpen} />
      <Spin
        spinning={loading}
        indicator={
          <LoadingOutlined
            style={{ fontSize: 64, color: '#624b78' }}
            spinning={loading.toString()}
          />
        }
      >
        <div>{renderUpcomingSessions()}</div>
      </Spin>
      <div className="flex horizontal-center">
        <ITCPolicy />
      </div>
      {/* PLACEHOLDER */}
      {/* <div className={`${styles['bg-layer']} ${styles.third} ${styles.new_indications_bg}`}>
        <div
          className={`wrapper fuzz ${styles.vbooth}`}
          style={{
            display: 'flex',
            margin: '0 auto',
            position: 'relative',
            padding: 20,
            maxWidth: 1000
          }}
        >
          <div className="flex horizontal-center flex-column">
            <Title
              className={`${styles.vboothtext} new_indications_h2_bg_title`}
              style={{
                fontFamily: 'Roboto-Bold',
                color: '#FFFFFF',
                fontSize: 30,
                marginBottom: 0
              }}
              level={2}
            >
              New Indications
              <div className="new_indications_h2_bg" />
            </Title>
            <Text
              className={`${styles.vboothtext}`}
              style={{
                fontFamily: 'Roboto-Medium',
                color: '#FFFFFF',
                fontSize: 30,
                lineheight: 1.3
              }}
            >
              Now Approved for the Treatment of Bipolar Depression&ndash;Bipolar I or II in Adults
            </Text>
            <a
              className={`${styles.vboothbutton}`}
              href="https://www.caplytahcp.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <BorderButton
                text="LEARN MORE"
                wrapperStyle={{ marginTop: 15, maxWidth: 280 }}
                wrapperClasses={'purple-btn'}
              />
            </a>
          </div>
          <img
            className={`${styles.vboothimg}`}
            style={{ flex: 1 }}
            src={require('../../img/Caplyta_box.png')}
            alt=""
          />
        </div>
      </div> */}
      <ITCBannerCarousel />
      <InterestTopics key={'interestTopics'} />
    </div>
  );
};

const mapStateToProps = ({ data, auth }) => {
  const { sessions, mySessions, meetingTitleCount, loaded, loading } = data;
  return { loaded, loading, sessions, mySessions, meetingTitleCount, user: auth.user };
};
export default connect(mapStateToProps, { getSessions })(withRouter(Home));
