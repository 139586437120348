import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
// import _ from 'lodash';
// import moment from 'moment';
// import { Avatar } from 'antd';

// import { CalendarFilled, ClockCircleFilled, HomeFilled, UserOutlined } from '@ant-design/icons';

import Home from './Home';
import Profile from './Profile';
import MySessions from './MySessions';
import UpcomingEvents from './UpcomingEvents';
import ErrorPage from './ErrorPage';
import Reports from './Reports';
import { getSessions } from '../../redux/dataSlice';
import { updatePassword } from '../../redux/authSlice';
// import config from '../../config';
import { getDropDownValues } from '../../redux/coreSlice';

import '../../Styles/App.scss';
// import { ReSetPasswordForm } from '../common';

const DashBoard = (props) => {
  const { dropDowns } = props;
  // const code = config.getUrlParams('code');

  // const [isModalVisible, setIsModalVisible] = React.useState(!completed_registration);

  // console.log('dashboard');
  const { loaded, getSessions } = props;
  React.useEffect(() => {
    if (!loaded) {
      getSessions();
    }
  }, [loaded, getSessions]);

  // React.useEffect(() => {
  //   if (completed_registration && isModalVisible) {
  //     setIsModalVisible(false);
  //   }
  // }, [completed_registration, isModalVisible]);

  React.useEffect(() => {
    dropDowns();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // const renderSessionCard = () => {
  //   if (loaded && code) {
  //     const session = _.find(allSessions, { code: code });
  //     if (!session) {
  //       return;
  //     }
  //     return (
  //       <div style={{ width: 300 }}>
  //         <h2>CONFIRMED FOR SESSION</h2>
  //         <p>{session.name}</p>
  //         <div>
  //           <p>
  //             <CalendarFilled /> &nbsp;
  //             {moment(session.start_date).format('dddd, MMMM D, YYYY')}
  //           </p>
  //           <p>
  //             <ClockCircleFilled /> &nbsp;
  //             {moment(session.start_date).format('h:mm A')} ({session.time_zone})
  //           </p>
  //           <p>
  //             <HomeFilled /> &nbsp;
  //             {session.session_type}
  //           </p>
  //         </div>

  //         <div
  //           style={{
  //             display: 'flex',
  //             borderTop: '1px solid black',
  //             borderBottom: '1px solid black',
  //             margin: 10,
  //             padding: 10
  //           }}
  //         >
  //           <Avatar
  //             shape="square"
  //             size={70}
  //             src={session.presenter.avatar}
  //             icon={!session.presenter.avatar && <UserOutlined />}
  //           />
  //           <div style={{ marginLeft: 5 }}>
  //             <span>
  //               {session.presenter.first_name} {session.presenter.last_name},{' '}
  //               {session.presenter.degree}
  //             </span>
  //             <br />
  //             <span>{session.presenter.affiliation}</span>
  //             <br />
  //             <span>
  //               {session.presenter.city}, {session.presenter.state}
  //             </span>
  //           </div>
  //         </div>
  //       </div>
  //     );
  //   }
  // };

  // const renderModal = () => {
  //   return (
  //     <Modal
  //       closable={false}
  //       maskClosable={false}
  //       keyboard={false}
  //       footer={null}
  //       visible={isModalVisible}
  //       width={700}
  //     >
  //       <div className="flex" style={{ justifyContent: 'space-between' }}>
  //         {renderSessionCard()}
  //         <div>
  //           <ReSetPasswordForm
  //             formTitle={
  //               'Please create a password to complete the registration process and gain access to the MKC in the future. Thank you'
  //             }
  //             buttonLabel={'Complete Registration'}
  //           />
  //         </div>
  //       </div>
  //     </Modal>
  //   );
  // };

  return (
    <>
      {/* {renderModal()} */}
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/sessions" exact component={MySessions} />
        <Route path="/upcoming-events" exact component={UpcomingEvents} />
        <Route path="/profile" exact component={Profile} />
        <Route path="/reports" exact component={Reports} />
        <Route exact component={ErrorPage} />
      </Switch>
    </>
  );
};

const mapStateToProps = ({ data, auth }) => {
  const { loaded, allSessions } = data;
  // const completed_registration = auth.user && auth.user.completed_registration;
  return { loaded, allSessions };
};

export default connect(mapStateToProps, {
  getSessions,
  updatePassword,
  dropDowns: getDropDownValues
})(DashBoard);
