/* eslint-disable import/no-cycle */
import fetchWrap from 'fetch-wrap';
// import _ from 'lodash';
// import ReactGA from 'react-ga4';
import { updateData } from './redux/authSlice';
import { store } from './store';

// let apiUrl = 'http://192.168.1.183:8000';
let apiUrl = 'https://api.itcipsychcenter.com';

if (process.env.NODE_ENV === 'production') {
  apiUrl = 'https://api.itcipsychcenter.com';
} else if (process.env.REACT_APP_APIENV === 'DEBUG') {
  apiUrl = 'http://localhost:8000';
}

// export const app_logo = require('./assets/logo.svg');

export const getUrlParams = (name) => {
  const results = new RegExp(`${name}=([^&]*)`).exec(window.location.href);
  if (results === null) {
    return null;
  }
  return decodeURI(results[1]);
};

export const hiddenEmail = (email) => {
  const splitted = email.split('@');
  return `${email[0]}${'*'.repeat(splitted[0].length - 1)}@${splitted[1]}`;
};

const checkAuth = () => {
  return async (url, options, fetch) => {
    const { auth } = store.getState();
    if (!options.headers) {
      window.location.href = '/auth/logout';
    }

    const response = await fetch(url, options);
    if (response.status !== 401) {
      return response;
    }
    // the current user is unauthorized.
    // lets try to refresh the token

    if (!auth.refreshToken) {
      window.location.href = '/auth/logout';
    }
    // lets try to refresh the access token
    const refreshUrl = `${apiUrl}/auth/token/refresh`;
    const data = {
      method: 'POST',
      headers: auth.headers,
      body: JSON.stringify({ refresh: auth.refreshToken })
    };
    const refreshResponse = await fetch(refreshUrl, data);
    if (refreshResponse.status === 401) {
      // the refresh token must be expired too.
      // log out the user and redirect to the login page.
      window.location.href = '/auth/logout';
    }
    const refreshJson = await refreshResponse.json();

    // create/update the new headers
    const headers = {
      ...auth.headers,
      Authorization: `Bearer ${refreshJson.access}`
    };

    // dispatch the refresh token action to update the redux store
    store.dispatch(
      updateData({
        accessToken: refreshJson.access,
        refreshToken: refreshJson.refresh,
        headers: headers
      })
    );
    return fetch(url, { ...options, headers: headers });
  };
};

const states = [
  { value: 'AL', name: 'Alabama' },
  { value: 'AK', name: 'Alaska' },
  { value: 'AS', name: 'American Samoa' },
  { value: 'AZ', name: 'Arizona' },
  { value: 'AR', name: 'Arkansas' },
  { value: 'CA', name: 'California' },
  { value: 'CO', name: 'Colorado' },
  { value: 'CT', name: 'Connecticut' },
  { value: 'DE', name: 'Delaware' },
  { value: 'DC', name: 'District Of Columbia' },
  { value: 'FM', name: 'Federated States Of Micronesia' },
  { value: 'FL', name: 'Florida' },
  { value: 'GA', name: 'Georgia' },
  { value: 'GU', name: 'Guam' },
  { value: 'HI', name: 'Hawaii' },
  { value: 'ID', name: 'Idaho' },
  { value: 'IL', name: 'Illinois' },
  { value: 'IN', name: 'Indiana' },
  { value: 'IA', name: 'Iowa' },
  { value: 'KS', name: 'Kansas' },
  { value: 'KY', name: 'Kentucky' },
  { value: 'LA', name: 'Louisiana' },
  { value: 'ME', name: 'Maine' },
  { value: 'MH', name: 'Marshall Islands' },
  { value: 'MD', name: 'Maryland' },
  { value: 'MA', name: 'Massachusetts' },
  { value: 'MI', name: 'Michigan' },
  { value: 'MN', name: 'Minnesota' },
  { value: 'MS', name: 'Mississippi' },
  { value: 'MO', name: 'Missouri' },
  { value: 'MT', name: 'Montana' },
  { value: 'NE', name: 'Nebraska' },
  { value: 'NV', name: 'Nevada' },
  { value: 'NH', name: 'New Hampshire' },
  { value: 'NJ', name: 'New Jersey' },
  { value: 'NM', name: 'New Mexico' },
  { value: 'NY', name: 'New York' },
  { value: 'NC', name: 'North Carolina' },
  { value: 'ND', name: 'North Dakota' },
  { value: 'MP', name: 'Northern Mariana Islands' },
  { value: 'OH', name: 'Ohio' },
  { value: 'OK', name: 'Oklahoma' },
  { value: 'OR', name: 'Oregon' },
  { value: 'PW', name: 'Palau' },
  { value: 'PA', name: 'Pennsylvania' },
  { value: 'PR', name: 'Puerto Rico' },
  { value: 'RI', name: 'Rhode Island' },
  { value: 'SC', name: 'South Carolina' },
  { value: 'SD', name: 'South Dakota' },
  { value: 'TN', name: 'Tennessee' },
  { value: 'TX', name: 'Texas' },
  { value: 'UT', name: 'Utah' },
  { value: 'VT', name: 'Vermont' },
  { value: 'VI', name: 'Virgin Islands' },
  { value: 'VA', name: 'Virginia' },
  { value: 'WA', name: 'Washington' },
  { value: 'WV', name: 'West Virginia' },
  { value: 'WI', name: 'Wisconsin' },
  { value: 'WY', name: 'Wyoming' }
];

// export const fetch = fetchWrap(fetch, [checkAuth()]);

const drTitles = [
  'CRNA DNP',
  'DNP',
  'DNP APRN FNP-BC',
  'DNP APRN FNP-C',
  'DNP CNM',
  'DNP CPNP',
  'DNP FNP-BC',
  'DNP-FNP',
  'DNP-FNP-C',
  'DO',
  'DO MBA',
  'DO MPH',
  'DO PHD',
  'DO-S',
  'DPM',
  'DPN',
  'DR',
  'FNP DNP',
  'MD',
  'MD DO',
  'MD FAAP',
  'MD FACP',
  'MD MPH',
  'MD MS',
  'MD PA',
  'MD PHD',
  'MD-S',
  'NP DNP',
  'PHAR',
  'PHARM',
  'PHARM D',
  'PHARM D RPH',
  'PHARMACIST',
  'PHARMD',
  'PHARMD RPH',
  'PHD',
  'PHD MN FNP-BC',
  'PSY',
  'PSYD',
  'REGISTERED PHARMACIST'
];

const npiRequiredDegrees = [
  'ACNP',
  'ACNP-BC',
  'ACNPC-AG',
  'AG-ACNP',
  'AGACNP-BC',
  'AGNP',
  'AGPCNP-BC',
  'ANP',
  'ANP-BC',
  'ANP-C',
  'ANP/GNP',
  'APRN ANP',
  'APRN ANP-BC GNP-BC',
  'APRN BC FNP',
  'APRN BC-FNP',
  'APRN CNP',
  'APRN CPNP',
  'APRN FNP-BC',
  'APRN FNP-C',
  'APRN NP-C',
  'APRN-CNP',
  'APRN-FNP-C',
  'APRN-NP',
  'ARNP',
  'ARNP FNP-BC',
  'ARNP-BC',
  'ARNP-C',
  'CFNP',
  'CNP',
  'CNP RNC',
  'CNPMSN BSN',
  'CPNP',
  'CPNP-PC',
  'CRNA DNP',
  'CRNP',
  'CRNP AGACNP-B',
  'CRNP AGACNP-BC',
  'CRNP FNP-C',
  'CRNP NP-C',
  'CRNP RN',
  'DHSC PA-C',
  'DNP',
  'DNP APRN FNP-BC',
  'DNP APRN FNP-C',
  'DNP CNM',
  'DNP CPNP',
  'DNP FNP-BC',
  'DNP-FNP',
  'DNP-FNP-C',
  'FAMILY NURSE PRACTITIONER',
  'FNP',
  'FNP C',
  'FNP DNP',
  'FNP-BC',
  'FNP-BC MSN',
  'FNP-C',
  'FNP-C PMHNP- BC',
  'FNP/BC',
  'FNPCNM',
  'MMSC PA-C',
  'MPAS PA-C MPH',
  'MS ARNP C-FNP',
  'MS ARNP CPNP-PC',
  'MS FNP-BC CMSRN',
  'MS PA C',
  'MS RN CPNP-PC',
  'MSN AGPCNP-BC',
  'MSN APRN FNP-C',
  'MSN APRN NP-C',
  'MSN CRNP',
  'MSN FNP',
  'MSN FNP-C',
  'MSN RN ACNP-BC',
  'MSN RN FNP',
  'MSN RN FNP-BC',
  'MSN RN PHN FNPC CCRN',
  'MSN-FNP',
  'MSNARNPFNP-BC',
  'MSNNP-C',
  'MSRN-BCFNP-BCONC',
  'NP',
  'NP DNP',
  'NP-C',
  'NPP32626',
  'NURSE PRACTITIONER',
  'NURSE PRACTITIONER ACUTE CARE',
  'PA',
  'PA C',
  'PA NEUROLOGY',
  'PA- C',
  'PA-C',
  'PA-C MPAS',
  'PA-C MS MPH',
  'PA-C RD',
  'PA-S',
  'PAC',
  'PHD MN FNP-BC',
  'PHYSICIAN ASSISTANT',
  'PMH-NP',
  'PMHNP',
  'PMHNP-BC',
  'PNP',
  'PHYSICIAN ASSITANT',
  'R-PAC',
  'RN ANP-C',
  'RN MSN CFNP',
  'RN MSN CPNP',
  'RN NP',
  'RN NP-C',
  'RNC MS WHNP',
  'RNMSN FNP-BC CNRN',
  'RNMSNAPN-BC',
  'RPA C',
  'RPA-C',
  'RPAC',
  'WHNP'
];

const webcastCodes = ['DH6SPQ', 'PPPTYP', '6VLBLM', 'D42W65', 'KF36RP', 'HTXBFE'];

const config = {
  baseApiUrl: apiUrl,
  fetch: fetchWrap(fetch, [checkAuth()]),
  states,
  drTitles,
  npiRequiredDegrees,
  getUrlParams,
  hiddenEmail,
  webcastCodes
};

export default config;
