import { createSlice } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import config from '../config';
import ReactGA from 'react-ga4';

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: null,
    accessToken: null,
    refreshToken: null,
    error: null,
    loading: false,
    headers: null,
    regSessionType: null,
    isVirtualHybrid: null
  },
  reducers: {
    updateData(state, action) {
      Object.assign(state, action.payload);
    },
    startLoading(state) {
      state.loading = true;
      state.error = null;
    },
    loginUserFail(state, action) {
      state.error = action.payload.error;
      state.loading = false;
    },
    loginUserSuccess(state, action) {
      Object.assign(state, action.payload);
      state.loading = false;
      state.error = null;
      state.regSessionType = null;
      state.isVirtualHybrid = null;
    },
    logoutUser(state) {
      // console.log('logoutUser');
      state.user = null;
      state.accessToken = null;
      state.refreshToken = null;
      state.headers = null;
    }
  }
});

export const { loginUserSuccess, loginUserFail, startLogin, logoutUser, updateData, startLoading } =
  authSlice.actions;

export default authSlice.reducer;

export const loginUser = (username, password, history) => {
  return async (dispatch) => {
    // start the user login process
    dispatch(startLoading());

    const requestPayload = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        username: username,
        password: password
      })
    };

    fetch(`${config.baseApiUrl}/auth/token`, requestPayload)
      .then((request) => {
        if (request.status === 403) {
          dispatch(
            loginUserFail({
              error: 'Your account has been disabled'
            })
          );
          ReactGA.event({
            category: 'Auth',
            action: 'Login Account Disabled'
          });
          return;
        }

        if (request.status !== 200) {
          dispatch(
            loginUserFail({
              error: 'Wrong username/password combination'
            })
          );
          ReactGA.event({
            category: 'Auth',
            action: 'Login Unsuccessful'
          });
          return;
        }
        ReactGA.event({
          category: 'Auth',
          action: 'Login Successful'
        });
        return request.json();
      })
      .then((response) => {
        if (!response) {
          return;
        }
        if (process.env.NODE_ENV === 'production') {
          Sentry.setUser({
            id: response.user.id,
            email: response.user.email
          });
        }
        dispatch(
          loginUserSuccess({
            accessToken: response.access,
            refreshToken: response.refresh,
            user: response.user,
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${response.access}`
            }
          })
        );
        const next = config.getUrlParams('next');
        if (next) {
          const redirectPath = `${next}${window.location.search
            .replace(`&next=${next}`, '')
            .replace(`next=${next}`, '')}`;
          return history.push(redirectPath);
        }
        return history.push(`/${window.location.search}`);
      })
      .catch((error) => {
        console.log('Login Error:', error);
        return dispatch(
          loginUserFail({
            error: 'Something went wrong. Please try again later.'
          })
        );
      });
  };
};

export const updateEmail = (email, password) => {
  return async (dispatch, getState) => {
    const { auth } = getState();
    dispatch(startLoading());
    const requestPayload = {
      method: 'POST',
      headers: auth.headers,
      body: JSON.stringify({
        email: email,
        password: password
      })
    };
    fetch(`${config.baseApiUrl}/auth/user/updateemail`, requestPayload).then((request) => {
      if (request.status !== 200) {
        dispatch(updateData({ loading: false }));
        alert('There was an error with your request. Please try again later.');
        return;
      }
      request.json().then((user) => {
        dispatch(updateData({ user: user, loading: false }));
      });
    });
  };
};

export const updatePassword = (newPassword, password) => {
  return async (dispatch, getState) => {
    const { auth } = getState();
    dispatch(startLoading());
    const requestPayload = {
      method: 'PUT',
      headers: auth.headers,
      body: JSON.stringify({
        newPassword: newPassword,
        password: password
      })
    };
    fetch(`${config.baseApiUrl}/auth/user/updatepwd`, requestPayload).then((request) => {
      if (request.status !== 200) {
        dispatch(updateData({ loading: false }));
        alert('There was an error with your request. Please reenter your password');
        return;
      }
      return dispatch(
        updateData({ loading: false, user: { ...auth.user, completed_registration: true } })
      );
    });
  };
};
