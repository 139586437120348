import React from 'react';
import _ from 'lodash';
import { Modal, Row, Col } from 'antd';

import BorderButton from './BorderButton';

import config from '../../config';

// const buttonStyle = {
//   backgroundColor: '#EFEFEF',
//   color: '#000000',
//   borderRadius: 3,
//   border: '1px solid #000000',
//   height: 30,
//   width: 75,
//   padding: '1px 6px',
//   cursor: 'pointer'
// };

const NpiLookup = (props) => {
  const { formValues, modalOpen, handleModalClose, setNpiInfo } = props;

  const [hcpList, setHcpList] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  // const validateNpi = (value) => {
  //   return /^\d+$/.test(value) && value.length === 10;
  // };

  React.useEffect(() => {
    getHcps();
  }, [modalOpen]); // eslint-disable-line

  const getHcps = () => {
    if (modalOpen) {
      const baseUrl = `${config.baseApiUrl}/api/npilookup?`;
      const params = _.filter(Object.keys(_.pickBy(formValues, _.identity)), (key) => {
        return _.includes(['first_name', 'last_name', 'npi', 'state'], key);
      })
        .map((key) => {
          return `${key}=${formValues[key].toString().trim()}`;
        })
        .join('&');

      const req = fetch(`${baseUrl}${params}`);

      req.then((response) => {
        response.json().then((json) => {
          if (_.isEmpty(json.results)) {
            setHcpList([]);
          } else {
            setHcpList(json.results);
          }
          setLoading(false);
        });
      });
      req.catch((err) => {
        console.log('err on getting api is', err);
        setLoading(false);
        setNpiInfo(null);
      });
    }
  };

  const handleClose = () => {
    handleModalClose();
    setHcpList(null);
    setLoading(false);
  };

  const handleSelect = (hcp) => {
    const { addresses, taxonomies } = hcp;
    const npiInfo = {
      // first_name: basic.first_name,
      // last_name: basic.last_name,
      npi: hcp.number
    };
    if (taxonomies.length) {
      npiInfo.licensed_state = hcp.taxonomies[0].state;
      npiInfo.license_number = hcp.taxonomies[0].license;
    }
    if (addresses.length) {
      npiInfo.address = addresses[0].address_1;
      npiInfo.office_phone = addresses[0].telephone_number;
      // npiInfo.city = addresses[0].city;
      // npiInfo.state = addresses[0].state;
      // npiInfo.zip_code = addresses[0].postal_code;
    }
    setNpiInfo(npiInfo);
  };

  const renderHcps = () => {
    if (loading) {
      return <div>Loading...</div>;
    }
    if (hcpList && !hcpList.length) {
      return (
        <div>
          <div>No records found.</div>
          <br />
          <br />
        </div>
      );
    }
    if (_.isArray(hcpList)) {
      const rows = _.map(hcpList, (hcp) => {
        const { basic, addresses, taxonomies } = hcp;

        return (
          <Row style={{ marginBottom: 25, borderBottom: '1px solid lightgray' }} key={hcp.number}>
            <Col span={4}>
              {/* <button type="button" style={buttonStyle} onClick={() => handleSelect(hcp)}>
                Select
              </button> */}
              <BorderButton onClick={() => handleSelect(hcp)}>Select</BorderButton>
            </Col>
            <Col span={6}>
              {hcp.number}
              <br />
              {`${basic.first_name} ${basic.middle_name ? basic.middle_name : ''} ${
                basic.last_name
              }`}
            </Col>
            <Col span={6}>
              {' '}
              {addresses[0].address_1}
              <br />
              {`${addresses[0].city} ${addresses[0].state}`}
            </Col>
            <Col span={8}>
              {' '}
              {`${taxonomies[0].state} ${taxonomies[0].license}`}
              <br />
              {taxonomies[0].desc}
            </Col>
          </Row>
        );
      });
      return (
        <div>
          <h3 style={{ marginBottom: 20 }}>
            Click Select next to the record that lists your information:
          </h3>
          {rows}
          <BorderButton wrapperStyle={{ margin: '0 auto' }} onClick={handleClose}>
            Close
          </BorderButton>
        </div>
      );
    }
  };

  return hcpList ? (
    <Modal
      key={'npiLookupModal'}
      visible={modalOpen}
      onOk={handleClose}
      onCancel={handleClose}
      destroyOnClose
      footer={null}
      centered
      style={{ padding: 'auto', top: 10, textAlign: 'center', width: 'auto', minWidth: 800 }}
    >
      {renderHcps()}
    </Modal>
  ) : (
    ''
  );
};

export default NpiLookup;
