import React from 'react';
import { Checkbox } from 'antd';
import ReactGA from 'react-ga4';

import BorderButton from './BorderButton';
import styles from '../../Styles/ITCInputs.module.scss';
// import '@lottiefiles/lottie-player';
// import { animationData } from '../animation/Index';

const ITCCheckbox = (props) => {
  // const [player, setPlayer] = useState(null);
  // const myRef = useRef(null);

  // useEffect(() => {

  //   let rr = {};
  //   rr['func' + props.id] = () => {
  //     if (animation === null) {
  //       setAnimation(buildAnimation())
  //     }
  //   }

  // window.addEventListener('load', () => {
  //   rr['func' + props.id]
  //   const player = myRef.current;
  //   player.load(animationData);
  //   setPlayer(player);
  // })

  // if (player !== null) {
  //   if (props.isChecked) {
  //     deselectRadioButton()
  //   } else {
  //     selectRadioButton()
  //   }
  //   return
  // }
  // console.log(rr)
  // return () => window.removeEventListener('load', rr['func' + props.id])
  // }, [props.isChecked])

  // const selectRadioButton = () => {
  //   // player.setDirection(1);
  //   // player.setSpeed(1)
  //   player.play()
  // }

  // const deselectRadioButton = () => {
  //   player.setDirection(-1);
  //   // player.setSpeed(3)
  //   player.play()
  // }

  // const status = props.isChecked ? 'checked' : '';

  return (
    <BorderButton wrapperClasses={styles.mkcInput}>
      <div key={props.id} className={`flex ${styles['checkbox-container']}`}>
        {/* <input
            key={props.id}
            type="radio"
            className={styles['checkbox-input']}
            checked={status}
            onClick={props.click}
            onChange={props.change}
          /> */}
        {/* <lottie-player ref={ myRef} /> */}
        <Checkbox
          checked={props.isChecked}
          type="checkbox"
          onChange={props.onChange}
          className="itcInputsCheckbox"
        />
        {props.topic.url ? (
          <a
            className="flex"
            href={props.topic.url}
            onClick={() => {
              ReactGA.event({
                category: 'My Interests',
                action: `${props.topic.name} Link Clicked`
              });
            }}
            target="_blank"
            rel="noopener noreferrer"
          >
            <span
              style={{
                marginLeft: 5,
                lineHeight: 1,
                fontSize: 11,
                display: 'inline-flex',
                alignItems: 'center',
                fontFamily: 'Roboto-Medium',
                textTransform: 'none'
              }}
            >
              {props.topic.name}
            </span>
            <span className={styles.go}>GO</span>
          </a>
        ) : (
          <span
            style={{
              marginLeft: 5,
              fontFamily: 'Roboto-Medium',
              textTransform: 'none',
              fontSize: 11
            }}
          >
            {props.topic.name}
          </span>
        )}
      </div>
    </BorderButton>
  );
};

export { ITCCheckbox };
