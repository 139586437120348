import React from 'react';
import ReactGA from 'react-ga4';
import _ from 'lodash';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Form } from 'antd';
import { RegisterAndProfileForm } from '../common';

import config from '../../config';
import { loginUserSuccess } from '../../redux/authSlice';
import { gtagConversion } from '../../util/gtag';

const RegisterForm = (props) => {
  const [form] = Form.useForm();
  const [error, setError] = React.useState(false);
  const [errorText, setErrorText] = React.useState(null);
  // const [regComplete, setRegComplete] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);

  const history = useHistory();
  const location = useLocation();

  const code = config.getUrlParams('code');

  const onFormFinish = (values) => {
    values.code = code;
    if (values.attendeereferral === 'Other') {
      values.attendeereferral = values.other_attendeereferral || values.attendeereferral;
    }

    values = _.reduce(
      values,
      (vals, value, key) => {
        // return (vals[key] = _.trim(value)), vals;
        // eslint-disable-next-line no-return-assign, no-sequences
        return (vals[key] = value && _.isFunction(value.trim) ? value.trim() : value), vals;
      },
      {}
    );
    // console.log('Success:', values);

    setSubmitting(true);
    fetch(`${config.baseApiUrl}/auth/register`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(values)
    }).then((response) => {
      if (response.status !== 201) {
        if (response.status === 400) {
          setErrorText(
            'Looks like you already have an account. Please log in with your email or npi number'
          );
        }
        console.log('err on registration.... ');
        setSubmitting(false);
        setError(true);
        return;
      }
      ReactGA.event({
        category: 'New Registration',
        action: code ? `New Registration with RSVP code ${code}` : 'New Registration'
      });
      if (config.webcastCodes.includes(code)) {
        gtagConversion('DC-9785414/event0/itci-000+per_session');
        try {
          window.uetq = window.uetq || [];
          window.uetq.push('event', 'engage', {
            event_label: 'hcp',
            event_category: 'form-submit'
          });
          const script = document.createElement('script');
          script.src = 'https://hmpads.advertserve.com/servlet/track?mid=15453&javascript=true';
          script.async = true;
          document.body.appendChild(script);
        } catch (tag_error) {
          console.log(tag_error);
        }
      }
      response.json().then((responseData) => {
        props.loginUserSuccess({
          accessToken: responseData.access,
          refreshToken: responseData.refresh,
          user: responseData.user,
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${responseData.access}`
          }
        });
        setErrorText(null);
        setError(false);
        setSubmitting(false);
        let nextPath = `/${location.search.replace('&openvideo=true', '')}${
          code ? '&confirmed_non_affiliation=1' : ''
        }`;
        if (values.attendanceType) {
          nextPath = `${nextPath}&isVirtualHybrid=${values.attendanceType === 'virtual' ? 1 : 0}`;
        }
        if (code && values.attendeereferral) {
          nextPath = `${nextPath}&attendeereferral=${values.attendeereferral}`;
        }
        history.push(nextPath);
      });
    });
  };

  return (
    <div>
      <RegisterAndProfileForm
        formType={'register'}
        form={form}
        onFormFinish={onFormFinish}
        error={error}
        errorText={errorText}
        submitting={submitting}
      />
    </div>
  );
};

export default connect(null, { loginUserSuccess })(RegisterForm);
