import React from 'react';
import ReactGA from 'react-ga4';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { Form, Input, Typography, Space } from 'antd';

import { connect } from 'react-redux';
import config from '../../config';
import { BorderButton } from '../common';

import { updateData, loginUserSuccess } from '../../redux/authSlice';
// import { setError } from '../../redux/dataSlice';

const { Title } = Typography;

const Styles = {
  tailLayout: {
    wrapperCol: {
      offset: 0,
      span: 24
    }
  },
  formItemStyle: {
    width: '50%',
    marginRight: 5,
    marginLeft: 5
  }
};

const UserLookupForm = (props) => {
  const [exists, setExists] = React.useState(true);
  const [denied, setDenied] = React.useState(false);
  // const [atCapacity, setAtCapacity] = React.useState(false);

  const onFinish = (values) => {
    // console.log('Success:', values);
    // setHasUserAccount(false);

    const payLoad = _.pickBy({ npi: values.npi, email: values.email }, _.identity);
    if (payLoad.email && payLoad.npi) {
      delete payLoad.npi;
    }

    setExists(true);
    setDenied(false);

    fetch(`${config.baseApiUrl}/auth/user/lookup`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payLoad)
    }).then((response) => {
      if (response.status === 404) {
        ReactGA.event({
          category: 'User Lookup',
          action: 'User Not Found'
        });
        setExists(false);
        // const searchParams = props.location.search
        //   ? `${props.location.search}&npiNotFound=true`
        //   : '?npiNotFound=true';
        // props.history.push(`/auth/register${searchParams}`);
        return;
      }
      if (response.status === 401) {
        ReactGA.event({
          category: 'User Lookup',
          action: 'User Not Authorized'
        });
        setDenied(true);
        return;
        // } else if (response.status === 403) {
        //   ReactGA.event({
        //     category: 'User Lookup',
        //     action: 'User Attended more than 3 meetings'
        //   });
        //   setAtCapacity(true);
        //   return;
      }
      if (response.status === 200) {
        ReactGA.event({
          category: 'User Lookup',
          action: 'Account Already Exists, Logging in'
        });
        // setHasUserAccount(true);
      }

      response.json().then((responseData) => {
        if (responseData.access) {
          ReactGA.event({
            category: 'User Lookup',
            action: 'Authenticating existing user'
          });
          props.loginUserSuccess({
            accessToken: responseData.access,
            refreshToken: responseData.refresh,
            user: responseData.user,
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${responseData.access}`
            }
          });
          const next = config.getUrlParams('next');
          if (next) {
            const redirectPath = `${next}${window.location.search
              .replace('&openvideo=true', '')
              .replace(`&next=${next}`, '')
              .replace(`next=${next}`, '')}`;
            return props.history.push(redirectPath);
          }
          props.history.push(`/${props.location.search.replace('&openvideo=true', '')}`);
        } else {
          ReactGA.event({
            category: 'User Lookup',
            action: 'User Found; Populating registration form'
          });
          setExists(false);
          // setAtCapacity(false);
          setDenied(false);

          props.updateData({ userFromLookup: responseData, loading: false });
        }
      });
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Form
      layout="vertical"
      name="user_lookup_form"
      initialValues={{
        remember: true
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Space direction="vertical" style={{ width: '100%' }}>
        <Title level={4}>Been to the Psych Center before?</Title>
        Welcome back! Enter either your email or NPI number for immediate access to the
        Intra-Cellular Therapies Psych Center.
        <div className="flex vertical-center">
          <Form.Item
            label="Email"
            name="email"
            style={Styles.formItemStyle}
            // rules={[
            //   {
            //     required: true,
            //     message: 'Please input your NPI number!'
            //   }
            // ]}
          >
            <Input />
          </Form.Item>
          <Title
            level={4}
            style={{
              marginBottom: 0
            }}
          >
            &nbsp;&nbsp;OR&nbsp;&nbsp;
          </Title>
          <Form.Item
            label="NPI"
            name="npi"
            style={Styles.formItemStyle}
            // rules={[
            //   {
            //     required: true,
            //     message: 'Please input your NPI number!'
            //   }
            // ]}
          >
            <Input />
          </Form.Item>
        </div>
        <Form.Item {...Styles.tailLayout}>
          <BorderButton
            wrapperStyle={{ marginBottom: 5, maxWidth: 150, margin: 'auto' }}
            buttonStyle={{ padding: 12 }}
            text="Search"
            htmlType="submit"
          />
        </Form.Item>
        {(!exists || denied) && (
          <p
            style={{
              color: 'gray',
              fontSize: 26,
              fontWeight: 'bold',
              textAlign: 'center',
              marginTop: -50
            }}
          >
            {denied
              ? 'Your account has been revoked access to this site. Please contact your representative if you think this is a mistake.'
              : 'Sorry, we were unable to find a match. Please register below.'}
          </p>
        )}
        {/* {atCapacity && (
          <div>
            <Title level={4} style={{ fontSize: 16 }}>
              Disclaimer
            </Title>
            You have reached the maximum number of programs that you may attend in a 12-month
            period.
          </div>
        )} */}
      </Space>
    </Form>
  );
};

export default withRouter(connect(null, { updateData, loginUserSuccess })(UserLookupForm));
