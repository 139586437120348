/* eslint-disable global-require */
import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import ReactGA from 'react-ga4';
import moment from 'moment';
import { Card, Button, Divider, Spin } from 'antd';
import {
  LoadingOutlined,
  CheckCircleTwoTone,
  DownOutlined,
  UpOutlined
  // ShareAltOutlined
} from '@ant-design/icons';
// eslint-disable-next-line import/no-cycle
import { BorderButton } from '.';
import ShareModal from './ShareModal';
import VideoModal from './VideoModal';
import RegisterConfirmationModal from './RegisterConfirmationModal';
import ObjectivesAndWarnings from './ObjectivesAndWarnings';
import { SessionCardIcons } from './SessionCardSections';
import { toggleSessionRegistration } from '../../redux/dataSlice';
import styles from '../../Styles/SessionCard.module.scss';

import config from '../../config';

const Styles = {
  dividerStyle: {
    margin: '10px 0',
    width: 'calc(100% + 48px)',
    marginTop: 10,
    marginBottom: 10,
    marginLeft: -24
  },
  actionButtonStyle: {
    borderRadius: 4,
    color: '#3C6279',
    lineHeight: 1,
    padding: '0px 40px',
    fontSize: 16,
    fontFamily: 'Roboto-Bold',
    marginTop: 5,
    borderColor: '#3C6279',
    borderWidth: 2
  }
};

const SessionCard = (props) => {
  const { session, mySessions, altLayout = false, isAnon = false } = props;
  const code = config.getUrlParams('code');
  const share = config.getUrlParams('share');

  const shareOpen = code === session.invitationcode && share;
  const hasVideo = Boolean(session.video.url);

  const [cardOpen, setCardState] = React.useState(false);

  const [shareModalOpen, setShareModalState] = React.useState(shareOpen);
  const [videoModalOpen, setVideoModalState] = React.useState(Boolean(props.isVideoOpen));
  const [registerModalOpen, setRegisterModalState] = React.useState(
    props.registerModalOpen || false
  );
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);

  const { rep, support_rep } = session;

  const isRegistered = Boolean(_.find(mySessions, (sessionId) => sessionId === session.id));

  const isBroadCast = session.is_broadcast;

  const renderToggleMoreButton = () => {
    return (
      <Button
        type="primary"
        onClick={() => {
          // if (isBroadCast) {
          //   return;
          // }
          setCardState(!cardOpen);
          ReactGA.event({
            category: 'Toggle Card details',
            action: cardOpen ? 'Close' : 'Open'
          });
        }}
        style={{
          width: 70,
          cursor: 'pointer',
          // opacity: 1,
          backgroundColor: 'transparent',
          border: 'none',
          color: '#305976',
          fontFamily: 'Roboto-Bold',
          fontWeight: 900,
          fontSize: '16px',
          boxShadow: 'none'
        }}
        size={'small'}
      >
        {cardOpen ? 'LESS' : 'MORE'} {cardOpen ? <UpOutlined /> : <DownOutlined />}
      </Button>
    );
  };

  const indicator =
    loading && !success ? (
      <LoadingOutlined style={{ fontSize: 64, marginLeft: -40, color: '#624b78' }} spinning />
    ) : (
      <CheckCircleTwoTone twoToneColor="#00a7b5" style={{ fontSize: 82, marginLeft: -40 }} />
    );

  // const isJoinSessionDisabled = () => {
  //   const date = new Date();
  //   const today = date.toJSON().split('.')[0].split('T')[0];
  //   return session.startdatetime.split('T')[0] !== today;
  // };

  // console.log('isJoinSessionDisabled', isJoinSessionDisabled());

  const updateSuccessStatus = (statusCode) => {
    if (_.includes([200, 201], statusCode)) {
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
        setLoading(false);
      }, 500); // a short delay to allow the animation to finish
    } else if (_.includes([401, 403, 406], statusCode)) {
      setLoading(false);
      // open modal for max session limit or not eligible
      props.toggleInfoModal(statusCode);
    }
    // console.log(statusCode);
  };

  const toggleRegistration = (attendanceType) => {
    setLoading(true);
    // setTimeout(() => setSuccess(true), 800);
    // setTimeout(() => {
    //   setLoading(false);
    //   setSuccess(false);
    // }, 1250);
    props.toggleSessionRegistration(session, updateSuccessStatus, attendanceType);
    ReactGA.event({
      category: 'Session Registration',
      action: `${session.id}: ${isRegistered ? 'Unregister' : 'Register'}`
    });
  };

  const renderRep = (rep) => {
    return (
      <div className="sub" style={{ color: '#8F9291', marginRight: 100 }}>
        <span style={{ color: '#797A7A', fontWeight: 'bold' }}>Sales Specialist</span>
        <br />
        <span>
          {rep.firstname} {rep.lastname}
        </span>
        <br />
        <span>{rep.phone}</span>
        <br />
        <span>{rep.email}</span>
      </div>
    );
  };

  return (
    <Card
      className={`${styles['session-card']}`}
      key={session.id.toString()}
      title={moment(session.startdatetime).format('MMM D')}
      extra={renderToggleMoreButton()}
      style={{
        fontSize: 16,
        minHeight: cardOpen ? 'auto' : 'auto',
        ...props.style
      }}
    >
      <ShareModal open={shareModalOpen} stateAction={setShareModalState} session={session} />
      {hasVideo && (
        <VideoModal
          open={videoModalOpen}
          stateAction={setVideoModalState}
          videoUrl={session.video.url}
          videoPoster={session.video.thumbnail}
          speakerName={session.video.speaker_name}
          videoTitle={session.video.title_name}
          sessionTitle={session.title}
        />
      )}
      <RegisterConfirmationModal
        open={registerModalOpen}
        stateAction={setRegisterModalState}
        onSuccess={toggleRegistration}
        session={session}
      />
      <Spin spinning={loading} indicator={indicator}>
        <div className={`${styles.flex} ${styles['mobile-column']}`}>
          <div className={`${styles.flex} flex-column ${styles['session-icons-wrapper']}`}>
            <SessionCardIcons altLayout={altLayout} session={session} />
          </div>
        </div>
        <div style={{ display: cardOpen ? 'block' : 'none' }}>
          <Divider style={Styles.dividerStyle} />
          <ObjectivesAndWarnings session={session} />
          <div className="flex">
            {renderRep(rep)}
            {support_rep && renderRep(support_rep)}
          </div>
        </div>

        <Divider style={Styles.dividerStyle} />

        <div
          className={`flex column-reverse ${styles['session-card-footer']}`}
          style={{
            alignItems: 'center',
            justifyContent: 'space-between',
            color: 'rgb(0, 89, 125)'
          }}
        >
          {!_.includes(['1:1', 'In-Office'], session.meetingtype) && (
            <Button
              className={`${styles['session-card-footer-share-btn']}`}
              style={{
                border: 'none',
                color: '#3C6279',
                display: 'flex',
                background: 'none',
                boxShadow: 'none',
                fontSize: 16
              }}
              icon={
                <img
                  src={require('../../img/Share_icon.png')}
                  alt="share"
                  style={{ height: 20, marginRight: 5, marginTop: 2 }}
                />
              }
              onClick={() => {
                setShareModalState(true);
                ReactGA.event({
                  category: 'ShareModal',
                  action: 'open'
                });
              }}
            >
              Share
            </Button>
          )}
          <div className={`${styles['inline-flex']} vertical-center horizontal-center`}>
            {hasVideo && (
              <BorderButton
                style={Styles.actionButtonStyle}
                onClick={() => {
                  setVideoModalState(true);
                  // ReactGA.event({
                  //   category: 'VideoModal',
                  //   action: `Open: ${session.video.url}`
                  // });
                }}
              >
                Learn More &nbsp;&nbsp;
                <img
                  src={require('../../img/play_button.png')}
                  alt="video"
                  height={25}
                  style={{ marginRight: -15 }}
                />
              </BorderButton>
            )}
            <div
              className={`flex ${styles['flex-column']} ${styles['horizontal-center']} vertical-center`}
              style={{ margin: '0 10px', textAlign: 'center' }}
            >
              {isRegistered && (
                <small
                  style={{
                    width: 100,
                    lineHeight: 1,
                    fontSize: 14,
                    wordBreak: 'keep-all',
                    whiteSpace: 'nowrap'
                  }}
                >
                  You are attending
                </small>
              )}

              <Button
                className={`${styles['session-card-footer-register-btn']}`}
                style={Styles.actionButtonStyle}
                onClick={() => {
                  if (isAnon) {
                    window.location.href = `/auth?code=${session.invitationcode}`;
                    return;
                  }
                  if (isRegistered || isBroadCast) {
                    toggleRegistration(); // unregister or register for broadcast
                  } else {
                    setRegisterModalState(true); // open confirmation modal to register
                  }
                }}
              >
                {isRegistered ? 'Unregister' : 'Register'}
              </Button>
            </div>
            {isRegistered && session.meetingtype === 'Virtual' && (
              <BorderButton
                wrapperStyle={{ alignSelf: 'center' }}
                text="Join Session >"
                // disabled={isJoinSessionDisabled()}
                onClick={() => {
                  window.open(session.join_url, '_blank');
                  ReactGA.event({
                    category: 'Join Session',
                    action: session.join_url
                  });
                }}
              />
            )}
          </div>
        </div>
      </Spin>
    </Card>
  );
};

const mapStateToProps = ({ data }) => {
  const { mySessions } = data;
  return { mySessions };
};

export default connect(mapStateToProps, { toggleSessionRegistration })(SessionCard);
