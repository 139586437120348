import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Switch, Route, useHistory, useLocation } from 'react-router-dom';
import { Row, Col, Typography } from 'antd';
import { isMobile } from 'react-device-detect';
import { getUrlParams } from '../../config';
import SessionComponent from './SessionComponent';
import AuthComponent from './AuthComponent';
import Logout from './LogOut';
import { Isi, ITCHeader, ITCFooter } from '../common';
import GreenBox from '../common/GreenBox';

// import RegisterForm from './RegisterForm';
// import ResetPassword from './ResetPassword';
import AuthenticateUser from './AuthenticateUser';
// import ThankYouPage from './ThankYouPage';
import ContactAndSamples from './ContactAndSamples';
import styles from '../../Styles/AuthComponent.module.scss';
import { getDropDownValues } from '../../redux/coreSlice';

const brainGameButton = require('../../img/brain-game-button.png');
const brainGameButton_mobile = require('../../img/BrainGame-Banner.png');

const { Title, Text } = Typography;

const Auth = ({ dropDowns }) => {
  const code = getUrlParams('code');
  const [validSession, setValidSession] = React.useState(false);
  const isAuth = window.location.pathname === '/auth';
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    window.addEventListener('load', () => {
      document.documentElement.className = 'auth';
    });

    return () => {
      document.documentElement.className = '';
    };
  });

  useEffect(() => {
    dropDowns();
  }, []); // eslint-disable-line

  return (
    <>
      <ITCHeader isAuth />
      {validSession && <GreenBox isAuth />}
      <Row className={`${styles['login-container']}`}>
        <Col
          xl={isAuth ? 6 : 12}
          lg={isAuth ? 6 : 12}
          md={24}
          sm={24}
          flex="1"
          className={`bg-purple-600 ${styles['ant-col']} ${styles['login-bg']} ${
            styles[isAuth ? 'shrink' : '']
          }`}
          style={{ position: 'relative', zIndex: 0, justifyContent: 'flex-start' }}
        >
          <div
            className={styles.beatTheBrainGame}
            onClick={() => {
              window.location.pathname = '/game';
            }}
            role="button"
            tabIndex={0}
          >
            <img src={isMobile ? brainGameButton_mobile : brainGameButton} alt="toBrainGame" />
          </div>
          <div className={styles.box}>
            <Title
              level={1}
              className={styles.h1}
              style={{ lineHeight: 1, fontFamily: 'Roboto-Bold' }}
            >
              Intra-Cellular Therapies Psych Center
            </Title>
            <Text className={`${styles.paragraph}`}>
              A gateway to engaging content from leading experts in mental health
            </Text>
          </div>
        </Col>

        <Col
          xl={isAuth ? 18 : 12}
          lg={isAuth ? 18 : 12}
          md={24}
          sm={24}
          flex="1"
          align="middle"
          className={`gradient--green ${styles['ant-col']}  ${styles['hide-scroll']}`}
          style={{ overflowY: 'scroll', paddingTop: '50px' }}
        >
          <div className={`${styles.formWrapper}`}>
            {code && <SessionComponent code={code} setValidSession={setValidSession} />}
            <Switch>
              <Route path="/auth" exact component={AuthComponent} />
              <Route
                path="/auth/login"
                render={() => {
                  history.push(`/auth${location.search}`);
                }}
              />
              <Route path="/auth/logout" component={Logout} />
              <Route
                path="/auth/register"
                render={() => {
                  history.push(`/auth${location.search}`);
                }}
              />
              <Route
                path="/auth/resetpassword/:userToken"
                render={() => {
                  history.push(`/auth${location.search}`);
                }}
              />
              <Route
                path="/auth/resetpassword"
                render={() => {
                  history.push(`/auth${location.search}`);
                }}
              />
              <Route path="/auth/authenticate/:userToken" component={AuthenticateUser} />
              <Route
                path="/auth/thankyou"
                render={() => {
                  history.push(`/auth${location.search}`);
                }}
              />
              <Route path="/auth/:requestType/:externalId" component={ContactAndSamples} />
            </Switch>
          </div>
        </Col>
      </Row>
      <Isi />
      <ITCFooter />
    </>
  );
};

export default connect(null, { dropDowns: getDropDownValues })(Auth);
