/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable global-require */
import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import ReactGA from 'react-ga4';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Typography, Divider, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
// import ReactGA from 'react-ga4';
import { SessionCard, ITCPolicy, InfoModal } from '../common';
// import BorderButton from '../common/BorderButton';
import styles from '../../Styles/UpcomingEvents.module.scss';
import { getSessions } from '../../redux/dataSlice';
import GreenBox from '../common/GreenBox';
// import RegisterBanner from '../common/RegisterBanner';
// import config from '../../config';
// import degreeTitles from './degreeTitles.json';

// const img = require('../../img/jumbotron-primary.svg');

const { Title, Text } = Typography;

const UpcomingEvents = (props) => {
  const { sessions, mySessions, loading } = props;

  // const [isMounted, setIsMounted] = React.useState(false);
  const [upcomingSessions, setUpcomingSessions] = React.useState([]);
  const [homeSessionsLoaded, setHomeSessionsLoaded] = React.useState(false);

  const [infoModalOpen, setInfoModalOpen] = React.useState(false);
  const [infoModalType, setInfoModalType] = React.useState(null);

  React.useEffect(() => {
    return () => {
      // get new sessions when this component unmounts
      // console.log('getting more sessions');
      getSessions();
      setUpcomingSessions([]);
      setHomeSessionsLoaded(false);
    };
  }, []);

  React.useEffect(() => {
    // console.log('in useEffect', sessions, 'xxx', mySessions);

    if (sessions.length && !homeSessionsLoaded && !upcomingSessions.length) {
      const now = moment(new Date()).format('YYYY-MM-DDTHH:mm:ss');
      const upcoming = _.filter(sessions, (session) => {
        return (
          !_.includes(mySessions, session.id) &&
          session.startdatetime >= now &&
          session.showInUpcomingSessions &&
          !session.is_broadcast
        );
      });
      setUpcomingSessions(upcoming);
      // upcoming.map((session) => console.log(session.meetingtype));
      setHomeSessionsLoaded(true);
    }
  }, [sessions, mySessions, upcomingSessions, homeSessionsLoaded]);

  const toggleInfoModal = (statusCode) => {
    console.log('toggleInfoModal', statusCode);
    setInfoModalOpen(!infoModalOpen);
    let modalType = statusCode === 401 ? 'maxAttendedMeetings' : 'notEligible';
    if (statusCode === 406) {
      modalType = 'maxCapacity';
    }
    console.log('modalType', modalType);
    setInfoModalType(infoModalOpen ? null : modalType);
    if (infoModalOpen) {
      ReactGA.event({
        category: `InfoModal: ${infoModalType}`,
        action: 'Close'
      });
    }
  };

  const renderUpcomingSessions = () => {
    return (
      <div>
        {upcomingSessions.length ? (
          upcomingSessions.map((session) => {
            return (
              <div key={session.id}>
                <SessionCard key={session.id} session={session} toggleInfoModal={toggleInfoModal} />
                <Divider size={50} />
              </div>
            );
          })
        ) : (
          <div style={{ height: 250 }}>
            {!loading && (
              <Text style={{ color: 'white' }}>
                Upcoming sessions will be listed here as they become available
              </Text>
            )}
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <InfoModal handleClose={toggleInfoModal} modalType={infoModalType} open={infoModalOpen} />
      <GreenBox />
      <div className={`${styles['upcoming-sessions']}`}>
        <div className={`${styles.wrapper}`}>
          <div className={`${styles['bg-layer']} ${styles.primary}`} />
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              margin: '0 auto',
              position: 'relative',
              top: 0
            }}
          >
            <Divider style={{ height: 50 }} type="vertical" />
            <div style={{ width: '100%', maxWidth: 1200, margin: '0 auto', textAlign: 'left' }}>
              <Title
                style={{
                  marginTop: 0,
                  marginBottom: 0,
                  fontFamily: 'Roboto-Medium'
                }}
                className="text__white"
              >
                Upcoming Events
              </Title>
            </div>
            <Divider style={{ height: 50 }} type="vertical" />
          </div>
          <Spin
            spinning={loading}
            indicator={
              <LoadingOutlined
                style={{ fontSize: 64, color: '#624b78' }}
                spinning={loading.toString()}
              />
            }
          >
            <div>{renderUpcomingSessions()}</div>
          </Spin>
          {/* <RegisterBanner /> */}
          <ITCPolicy />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ data, auth }) => {
  const { sessions, mySessions, loaded, loading } = data;
  return { loaded, loading, sessions, mySessions, user: auth.user };
};
export default connect(mapStateToProps, { getSessions })(withRouter(UpcomingEvents));
