import React from 'react';
import ReactPlayer from 'react-player';
import ReactGA from 'react-ga4';

const VideoPlayer = (props) => {
  // console.log('props', props);
  const { videoUrl, videoPoster, gaTitle } = props;

  // const videoFileName = videoUrl.split('/').pop();

  // React.useEffect(() => {
  //   ReactGA.event({
  //     category: 'VideoPlayer',
  //     action: `Play: ${gaTitle}`
  //   });
  // }, []); // eslint-disable-line

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <ReactPlayer
        width="90%"
        height="90%"
        url={videoUrl}
        controls
        playing
        // onPlay={() => console.log('onPlay')}
        onEnded={() => {
          ReactGA.event({
            category: 'VideoPlayer',
            action: `Ended: ${gaTitle}`
          });
        }}
        onSeek={(e) => {
          // console.log('onSeek', e);
          ReactGA.event({
            category: 'VideoPlayer',
            action: `Seek: ${gaTitle}`
          });
        }}
        config={{
          file: {
            attributes: {
              poster: videoPoster
            }
          }
        }}
      />
    </div>
  );
};

export default VideoPlayer;
