import React from 'react';
import { connect } from 'react-redux';
import { Modal, Typography } from 'antd';
import { BorderButton } from '../common';
import RegisterForm from './RegisterForm';
import UserLookupForm from './UserLookupForm';
import RepContactModal from './RepContactModal';
import { updateData } from '../../redux/authSlice';

// import config from '../../config';

const { Title } = Typography;

// import config from '../../config';

const AuthComponent = (props) => {
  const [modalOpen, setModalOpen] = React.useState(false);
  // reset user from lookup and set err to null
  props.updateData({ userFromLookup: null, error: null });
  React.useEffect(() => {
    if (window.location.search.indexOf('thanksforplaying') > -1) {
      setModalOpen(true);
    }
  }, []);

  const handleClose = () => {
    setModalOpen(false);
  };

  const renderModal = () => {
    if (!modalOpen) return null;
    return (
      <Modal
        key={'regconfirmmodal'}
        visible={modalOpen}
        onOk={handleClose}
        onCancel={handleClose}
        destroyOnClose
        footer={null}
        centered
        // width={'80%'}
        style={{ padding: 'auto', top: 10, textAlign: 'center' }}
      >
        <div style={{ padding: 25 }}>
          <Title style={{ color: '#04799e', fontSize: 22, marginBottom: 30 }}>
            Thanks for playing The Brain Game! Register now to access all of the resources the Psych
            Center has to offer.
          </Title>
          <BorderButton
            text={'CLOSE WINDOW'}
            onClick={handleClose}
            wrapperStyle={{ maxWidth: 200, margin: 'auto', marginBottom: 8 }}
          />
        </div>
      </Modal>
    );
  };

  return (
    <>
      {renderModal()}
      <RepContactModal />
      <UserLookupForm />
      <div
        className="flex vertical-center horizontal-center"
        style={{
          margin: '20px 0'
        }}
      >
        <div className="line" />
      </div>
      <RegisterForm />
    </>
  );
};

export default connect(null, { updateData })(AuthComponent);
