import React from 'react';
import { Typography, Divider } from 'antd';
const { Title } = Typography;

const ObjectivesAndWarnings = (props) => {
  const { objective, is_broadcast } = props.session;

  return (
    <div style={{ color: '#8F9291' }}>
      <Title level={5} style={{ fontWeight: 'bold', color: '#797A7A' }}>
        Program Objectives
      </Title>
      <span dangerouslySetInnerHTML={{ __html: objective }} />
      {!is_broadcast && <Divider />}
      {/* <Title level={5} style={{ fontWeight: 'bold', display: 'inline' }}>
        Warnings and Precautions:&nbsp;
      </Title>
      <span style={{ fontSize: 16 }}>
        If a serious hypersensitivity reaction occurs, discontinue ITC Caplyta and initiate
        appropriate therapy. Serious hypersensitivity reactions have included dyspnea and rash, and
        can occur days after administration.
      </span>
      <Divider /> */}
    </div>
  );
};

const areEqual = (prevProps, nextProps) => {
  return prevProps.session.id === nextProps.session.id;
};

export default React.memo(ObjectivesAndWarnings, areEqual);
