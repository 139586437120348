import React from 'react';

const BorderButton = (props) => {
  const { disabled } = props;
  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className={`border-button-wrapper ${props.wrapperClasses || ''}`}
      style={props.wrapperStyle}
      onClick={props.onClick}
    >
      <button
        style={{
          ...props.buttonStyle,
          height: 'auto !important',
          cursor: disabled ? 'not-allowed' : 'pointer',
          opacity: disabled ? 0.5 : 1
        }}
        // eslint-disable-next-line react/button-has-type
        type={props.htmlType || 'submit'}
        disabled={disabled}
      >
        {props.text || props.children}
      </button>
    </div>
  );
};

export default BorderButton;
