/* eslint-disable global-require */
import React, { useEffect } from 'react';

import { isMobile, useMobileOrientation } from 'react-device-detect';

import ReactGA from 'react-ga4';

const bannerImg = require('../../img/BrainGameBannerTop.png');
const bannerImgMobile = require('../../img/BrainGameBannerTopMobile.png');

const RegisterBanner = () => {
  const mobileOrientation = useMobileOrientation();
  const [bannerImage, setBannerImage] = React.useState(bannerImg);

  useEffect(() => {
    setBannerImage(isMobile && mobileOrientation.isPortrait ? bannerImgMobile : bannerImg);
  }, [mobileOrientation]);

  return (
    <div
      className="game_banner_parent"
      style={{
        cursor: 'pointer',
        maxWidth: isMobile && mobileOrientation.isPortrait && '100%'
      }}
      role="button"
      tabIndex={0}
      onClick={() => {
        ReactGA.event({
          category: 'BrainGameBanner',
          action: 'Top Brain Game banner clicked'
        });
        window.open('/game', '_self');
      }}
    >
      <img
        className="game_banner_bg"
        src={bannerImage}
        alt="Register banner"
        // style={{ minHeight: 210 }}
      />
    </div>
  );
};

export default RegisterBanner;
