/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button, Layout, Menu } from 'antd';
import ReactGA from 'react-ga4';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { isMobile, useMobileOrientation } from 'react-device-detect';

import SlideMenuContext from '../../context/SlideMenuContext';
// import { LogoutOutlined } from '@ant-design/icons';
import styles from '../../Styles/ITCHeader.module.scss';
// import config from '../../config';
import RepContactRequest from './RepContactRequest';

const itcLogo = require('../../img/header-logo.png');
const itcLogoMobile = require('../../img/HeaderMobile.png');
const itcLogoSimple = require('../../img/header-logo-simple.png');
const logoutIcon = require('../../img/logout_icon.png');
const logoutIconActive = require('../../img/logout_icon_active.png');
const profileIcon = require('../../img/profile_icon.png');
const profileIconActive = require('../../img/profile_icon_active.png');

const { Header } = Layout;

// const mkcLogo = require('../img/logo-official.svg');

const ITCHeader = (props) => {
  // const [modal, modalContext] = Modal.useModal();
  const [isContactRepModalOpen, setIsContactRepModalOpen] = useState(false);
  const [logoutPopoverVisible, setLogoutPopoverVisible] = useState(false);
  const mobileOrientation = useMobileOrientation();

  const {
    isSlideMenuOpen,
    setIsSlideMenuOpen,
    isSlideMenuContactRepClicked,
    setIsSlideMenuContactRepClicked,
    setIsSlideMenuLogoutClicked,
    isSlideMenuLogoutClicked
  } = useContext(SlideMenuContext);

  const isMobileVertical = isMobile && mobileOrientation.isPortrait;

  useEffect(() => {
    if (isSlideMenuContactRepClicked) {
      menuOnClick({ key: 'contactSpecialist' });
      setIsSlideMenuContactRepClicked(false);
    }
  }, [isSlideMenuContactRepClicked]); // eslint-disable-line

  useEffect(() => {
    if (isSlideMenuLogoutClicked) {
      menuOnClick({ key: 'logout' });
      setIsSlideMenuLogoutClicked(false);
    }
  }, [isSlideMenuLogoutClicked]); // eslint-disable-line

  const toggleContactRepModal = () => {
    setIsContactRepModalOpen(!isContactRepModalOpen);
  };

  const toggleLogoutPopover = () => {
    // console.log('toggleLogoutPopover');
    setLogoutPopoverVisible(!logoutPopoverVisible);
  };

  const menuOnClick = ({ key }) => {
    if (key !== 'burger') {
      props.scrollToTop();
    } else {
      setIsSlideMenuOpen(!isSlideMenuOpen);
    }
    if (key === 'contactSpecialist') {
      return toggleContactRepModal();
    }
    if (key === 'samplesRequest') {
      ReactGA.event({
        category: 'Request Samples',
        action: 'Request Samples'
      });
      return;
    }
    if (key === 'prescribingInfo') {
      ReactGA.event({
        category: 'Prescribing Information',
        action: 'Prescribing Information'
      });
    }
    if (key === 'logout') {
      return toggleLogoutPopover();
    }
    if (key !== 'burger') {
      props.history.push(key);
    }
  };

  const renderLogoutPopover = () => {
    if (logoutPopoverVisible) {
      return (
        <div
          style={{
            position: 'absolute',
            right: 100,
            top: 10,
            width: 300,
            backgroundColor: 'white',
            padding: '5px 20px',
            border: '1px solid darkgrey',
            borderRadius: 5,
            textAlign: 'center'
          }}
        >
          {/* <p style={{ fontSize: 18, fontWeight: 'bold' }}>Would you like to sign out?</p> */}
          <h3>Would you like to sign out?</h3>
          <div className="flex" style={{ width: '100%', justifyContent: 'space-evenly' }}>
            <Button
              style={{
                backgroundColor: 'rgb(0, 89, 125)',
                color: 'white',
                border: 'none',
                borderRadius: 5,
                fontWeight: 'bold'
              }}
              onClick={() => {
                setIsSlideMenuOpen(false);
                props.history.push('/auth/logout');
              }}
            >
              Sign Out
            </Button>
            <Button style={{ border: 'none', fontWeight: 'bold' }} onClick={toggleLogoutPopover}>
              Cancel
            </Button>
          </div>
        </div>
      );
    }
  };

  const isDashboard = !props.isAuth && !props.isAnon && !props.isVirtualWebcasts;

  const getImageSource = () => {
    if (props.isVirtualWebcasts) return itcLogoSimple;

    return isMobileVertical ? itcLogoMobile : itcLogo;
  };

  return (
    <div>
      {isDashboard && (
        <div className={`${styles.headerWarning}`}>
          This site is for US healthcare professionals only.
        </div>
      )}
      <Header
        id="itc-header"
        className={`${styles.header} ${styles['ant-layout-header']} `}
        style={{
          position: 'fixed',
          top: isDashboard && 25,
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          fontFamily: 'Roboto-Bold'
        }}
      >
        <img
          className={`${styles.logo}`}
          onClick={() => !props.isVirtualWebcasts && props.history.push('/')}
          alt="logo"
          style={{ cursor: 'pointer' }}
          src={getImageSource()}
        />

        {isDashboard && (
          <>
            <Menu
              // inlineCollapsed={false}
              key={'menu'}
              theme="light"
              mode="horizontal"
              selectedKeys={[props.location.pathname]}
              onClick={menuOnClick}
              disabledOverflow
            >
              {/* <Menu.Item
                className={`${styles['hide-desktop']} header-active-underline header-item`}
                key="/"
              >
                <span style={{ position: 'relative' }}>
                  Home
                  <span className="header-active-underline-span" />
                </span>
              </Menu.Item> */}
              {/* <Menu.Item
                className={`${styles['hide-desktop']} header-active-underline header-item`}
                key="/sessions"
              >
                <span style={{ position: 'relative' }}>
                  My Sessions
                  <span className="header-active-underline-span" />
                </span>
              </Menu.Item> */}

              {/* <Menu.Item key="/upcoming-events" className={`${styles['hide-desktop']} header-item`}>
                <span className={`${styles['btn__outline--green']}`}>Upcoming Events</span>
              </Menu.Item>
              <Menu.Item key="samplesRequest" className={`${styles['hide-desktop']} header-item`}>
                <a
                  href="https://itci.mysamplecloset.com/login"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className={`${styles['btn__outline--green']}`}>Request Samples</span>
                </a>
              </Menu.Item> */}
              {/* <Menu.Item
                key="contactSpecialist"
                className={`${styles['hide-desktop']} header-item`}
              >
                <span className={`${styles['btn__outline--green']}`}>Contact a Representative</span>
              </Menu.Item> */}
              <Menu.Item
                className={`${styles['hide-desktop']} header-active-underline header-item`}
                key="/profile"
              >
                <img
                  src={window.location.pathname === '/profile' ? profileIconActive : profileIcon}
                  alt="profile"
                  height={30}
                />
              </Menu.Item>
              <Menu.Item
                className={`${styles['hide-desktop']} header-active-underline header-item`}
                key="logout"
              >
                <img
                  src={logoutPopoverVisible ? logoutIconActive : logoutIcon}
                  alt="logout"
                  height={30}
                />
              </Menu.Item>

              <Menu.Item key="burger" className={`${styles.burgerbars} header-item`}>
                <FontAwesomeIcon icon={faBars} size="3x" style={{ verticalAlign: 'middle' }} />
              </Menu.Item>
            </Menu>
            <RepContactRequest
              visible={isContactRepModalOpen}
              onClose={toggleContactRepModal}
              auth={props.auth}
            />
          </>
        )}
        {!isDashboard && (
          <span
            className={`${styles['header-disclaimer']}`}
            style={{ color: '#2C5773', fontFamily: 'Roboto-Bold' }}
          >
            This site is for US healthcare professionals only.
          </span>
        )}
        {renderLogoutPopover()}
      </Header>
    </div>
  );
};

const mapStateToProps = ({ auth }) => {
  return { auth };
};

export default withRouter(connect(mapStateToProps, null)(ITCHeader));
