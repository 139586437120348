import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import ReactGA from 'react-ga4';
import { v4 as uuidv4 } from 'uuid';
// import Analytics from 'react-router-ga';
import 'tailwindcss/tailwind.css';
import { store, persistor } from './store';

import * as serviceWorker from './serviceWorker';

// import Main from './components';
import Router from './Router';

import './Styles/standard.css';

const isProd = process.env.NODE_ENV === 'production';

// let GAMeasurementID = 'UA-15348392-65'; // beta
let GAMeasurementID = 'G-FJSQXYHR7E'; // beta
if (window.location.hostname === 'www.itcipsychcenter.com') {
  // GAMeasurementID = 'UA-15348392-64';
  GAMeasurementID = 'G-65WPDSNGVX';
}

ReactGA.initialize(GAMeasurementID, { debug: !isProd });

const sessionID = sessionStorage.getItem('sessionID');
if (!sessionID) {
  sessionStorage.setItem('sessionID', uuidv4().replace(/\W/g, ''));
}

if (isProd) {
  Sentry.init({
    dsn: 'https://7a224d895cf04c96b597c2e0a1a59c2b@o124700.ingest.sentry.io/4504277032173568',
    integrations: [new Integrations.BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 0.2
  });
}

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <PersistGate loading={null} persistor={persistor}>
        <Router store={store} />
      </PersistGate>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);

serviceWorker.unregister();
