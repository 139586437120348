/* eslint-disable global-require */
import React from 'react';
import { Layout } from 'antd';
import styles from '../../Styles/ITCFooter.module.scss';
// import Isi from '../common/Isi';

const { Footer } = Layout;
// const { Text } = Typography;

const ITCFooter = (props) => {
  return (
    <Footer>
      <div
        className={`${styles['itc-footer']} ${props.isAlwaysVisible ? styles.absoluteVisible : ''}`}
      >
        <div>
          <img
            alt="logo"
            src={require('../../img/itc-logo-white.png')}
            className={`${styles['itc-footer-logo']}`}
          />
        </div>
        <div className={`${styles['itc-footer-right']}`} style={{ flex: 1 }}>
          <ul style={{ whiteSpace: 'no-wrap' }} className={`${styles['itc-footer-right-links']}`}>
            <li
              style={{
                marginRight: '4px'
              }}
            >
              <a
                href="https://www.intracellulartherapies.com/terms-of-use/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms of use
              </a>{' '}
              |
            </li>
            <li
              style={{
                marginRight: '4px'
              }}
            >
              <a
                href="https://www.intracellulartherapies.com/privacy-policy/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>{' '}
              |
            </li>
            <li
              style={{
                marginRight: '4px'
              }}
            >
              <a
                href="https://www.intracellulartherapies.com/cookies-policy/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Cookie Policy
              </a>{' '}
              |
            </li>
            <li
              style={{
                marginRight: '4px'
              }}
            >
              <a
                href="https://www.intracellulartherapies.com/Contact-us/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Contact Us
              </a>{' '}
              |
            </li>
            <li
              style={{
                marginRight: '4px'
              }}
            >
              <a
                href="https://www.caplytahcp.com/disclosure-information"
                target="_blank"
                rel="noopener noreferrer"
              >
                Disclosures
              </a>
            </li>
          </ul>
          <div className={`${styles['itc-footer-right-support']}`}>
            For technical support, please{' '}
            <a href="mailto:ITCIPsychCenter@synapseny.com">click here</a> to contact our helpdesk
          </div>
          <div className={`${styles['itc-footer-right-trademark']}`}>
            CAPLYTA is a registered trademark of Intra-Cellular Therapies, Inc.
            <br />
            &copy;2024 Intra-Cellular Therapies, Inc., All rights reserved.&nbsp;
            {props.jobCode || 'US-CAP-2400286 06/2024'}
          </div>
        </div>
      </div>
    </Footer>
  );
};

export default ITCFooter;
