import React from 'react';
import ReactGA from 'react-ga4';
import { Checkbox, Typography, Modal } from 'antd';
import BorderButton from './BorderButton';

const { Title, Text } = Typography;

const RegisterConfirmationModal = (props) => {
  const { open, session, stateAction, onSuccess } = props;

  const [checkBoxChecked, setCheckBoxChecked] = React.useState(false);
  const [attendanceType, setAttendanceType] = React.useState(null);

  const handleModalClose = (e) => {
    stateAction(false);
    ReactGA.event({
      category: 'RegisterConfirmationModal',
      action: `Close: ${session.code}`
    });
  };

  const handleConfirm = () => {
    onSuccess(attendanceType);
    stateAction(false);
    ReactGA.event({
      category: 'RegisterConfirmationModal',
      action: `Confirm: ${session.code}`
    });
  };

  const isSubmitButtonDisabled = () => {
    return !checkBoxChecked || (session.is_virtual_hybrid && !attendanceType);
  };

  const renderVirtualHybridCheckboxes = () => {
    if (session.is_virtual_hybrid) {
      return (
        <div style={{ marginTop: 15 }}>
          <Text>
            The program you are registering for is hybrid and can accomodate both in -person and
            virtual attendees. Please confirm how you are planning to attend by choosing from the
            options below.
          </Text>
          <div className="flex horizontal-center" style={{ marginTop: 15 }}>
            <Checkbox
              onChange={() => {
                setAttendanceType('in-person');
              }}
              // style={{ color: 'gray', textAlign: 'left', width: '90%', marginTop: 20 }}
              // value="in-person"
              checked={attendanceType === 'in-person'}
            >
              In Person
            </Checkbox>
            <Checkbox
              onChange={() => {
                setAttendanceType('virtual');
              }}
              // style={{ color: 'gray', textAlign: 'left', width: '90%', marginTop: 20 }}
              // value="virtual"
              checked={attendanceType === 'virtual'}
            >
              Virtual
            </Checkbox>
          </div>
        </div>
      );
    }
  };

  return (
    <Modal
      key={'regconfirmmodal'}
      visible={open}
      onOk={handleModalClose}
      onCancel={handleModalClose}
      destroyOnClose
      footer={null}
      centered
      // width={'80%'}
      style={{ padding: 'auto', top: 10, textAlign: 'center', fontWeight: 'bold' }}
    >
      <Title style={{ color: '#04799e', fontSize: 22 }}>
        You&apos;re about to register for <br />
        {session.title}
      </Title>
      <Text>
        Before you can complete registration,
        <br />
        {session.is_virtual_hybrid
          ? 'please confirm the following'
          : 'please confirm by marking the checkbox below:'}
      </Text>
      {renderVirtualHybridCheckboxes()}
      <Checkbox
        onChange={() => {
          setCheckBoxChecked(!checkBoxChecked);
        }}
        style={{ textAlign: 'left', width: '90%', marginTop: 20 }}
      >
        I certify that I am not an employee of the speaker&apos;s own medical practice (same
        physical location) or a staff member of a facility for which the speaker is a medical
        director (whose job function it is to educate those within her or his supervisory
        authority).
      </Checkbox>
      <div style={{ display: 'flex', flexWrap: 'wrap', margin: 10 }}>
        <BorderButton
          wrapperClasses={'blue-button-text'}
          onClick={handleModalClose}
          buttonStyle={{ background: 'white' }}
          wrapperStyle={{
            maxWidth: 200,
            margin: 'auto',
            marginBottom: 8
          }}
        >
          <span style={{ textTransform: 'none', fontSize: 14 }}>Cancel Registration</span>
        </BorderButton>
        <BorderButton
          disabled={isSubmitButtonDisabled()}
          onClick={() => {
            if (!isSubmitButtonDisabled()) {
              handleConfirm();
            }
          }}
          wrapperStyle={{ maxWidth: 200, margin: 'auto', marginBottom: 8 }}
        >
          <span style={{ textTransform: 'none', fontSize: 14 }}>Confirm</span>
        </BorderButton>
      </div>
    </Modal>
  );
};

export default RegisterConfirmationModal;
