import React, { useRef } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Typography, Divider } from 'antd';
import { SessionCard, ITCPolicy } from '../common';
import GreenBox from '../common/GreenBox';
import styles from '../../Styles/MySessions.module.scss';

const { Title } = Typography;

const MySessions = (props) => {
  const { mySessions, sessions, loaded } = props;
  const ref = useRef(null);
  const [attendingSessions, setAttendingSessions] = React.useState([]);
  // const [loaded, setLoaded] = React.useState(false);

  React.useEffect(() => {
    if (
      loaded &&
      !attendingSessions.length &&
      sessions.length &&
      mySessions.length > attendingSessions.length
    ) {
      setAttendingSessions(
        _.compact(
          _.map(mySessions, (sessionId) => {
            return _.find(sessions, { id: sessionId });
          })
        )
      );
    }
  }, [attendingSessions, sessions, mySessions, loaded]);

  const renderMySessions = () => {
    return attendingSessions.map((session) => {
      return (
        <div key={session.id}>
          <SessionCard
            key={session.id}
            session={session}
            style={{ width: '100%', maxWidth: '100%' }}
          />
          <Divider size={50} />
        </div>
      );
    });
  };

  return (
    <>
      <GreenBox />
      <div
        className={`flex flex-column ${styles['my-sessions']}`}
        style={{ minHeight: 1, alignItems: 'center' }}
      >
        <div className={`${styles.wrapper}`}>
          <div ref={ref} className={`${styles['bg-layer']} ${styles.primary}`} />
          <div className={`${styles.wrapper}`} style={{ minHeight: 300 }}>
            <Divider size={100} />
            <Title level={3} style={{ color: 'white', marginBottom: 0 }}>
              Intra-Cellular Therapies Psych Center
            </Title>
            <Title style={{ marginTop: 5 }} level={1} className={`${styles['title--white']}`}>
              My Sessions
            </Title>

            {mySessions.length > 0 ? (
              <Title level={4} className={`${styles['title--white']}`}>
                Thank you for registering for the sessions listed below.
              </Title>
            ) : (
              <Title level={4} className={`${styles['text--white']}`} style={{ fontWeight: 300 }}>
                You have not registered for any sessions.
              </Title>
            )}

            <Divider size={100} />
          </div>
          {renderMySessions()}
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <ITCPolicy />
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ data }) => {
  const { mySessions, sessions, loaded } = data;
  return { mySessions, sessions, loaded };
};
export default connect(mapStateToProps, null)(MySessions);
