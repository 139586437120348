import React from 'react';

import { Checkbox, Row, Col } from 'antd';

const contactTopics = [
  'Office Visit',
  'Samples',
  'Upcoming Educational Programs',
  'Patient Education/Access Materials',
  'Literature',
  'Savings Card/Vouchers',
  'Other'
];

const RepContactTopics = ({ topics, handleTopicSelect }) => {
  return (
    <div style={{ marginTop: 20, textAlign: 'left', width: '80%', margin: '0 auto' }}>
      <Row
        gutter={{
          xs: 12,
          sm: 12,
          md: 24,
          lg: 24
        }}
      >
        {contactTopics.map((topicName, index) => (
          <Col key={index} span={12}>
            <Checkbox
              onChange={handleTopicSelect(topicName)}
              checked={topics.includes(topicName)}
              className="contactrep-topic-checkbox"
            >
              <span style={{ color: '#00597D' }}>{topicName}</span>
            </Checkbox>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default RepContactTopics;
