import React from 'react';
import ReactGA from 'react-ga4';
import { Modal } from 'antd';
import VideoPlayer from './VideoPlayer';

const VideoModal = (props) => {
  const { open, stateAction, videoUrl, videoPoster, speakerName, sessionTitle } = props;

  const [gaTitle, setGaTitle] = React.useState('');

  React.useEffect(() => {
    setGaTitle(`${speakerName} - ${sessionTitle} - ${videoUrl.split('/').pop()}`);
  }, [speakerName, sessionTitle, videoUrl]);

  React.useEffect(() => {
    // we record a play once the modal is open
    if (open) {
      ReactGA.event({
        category: 'VideoPlayer',
        action: `Play: ${gaTitle}`
      });
    }
  }, [open]);

  const handleModalClose = (e) => {
    stateAction(false);
    ReactGA.event({
      category: 'VideoPlayer',
      action: `Close: ${gaTitle}`
    });
  };

  // console.log('VideoModal open', open);
  return (
    <Modal
      key={'modal'}
      visible={open}
      onOk={handleModalClose}
      onCancel={handleModalClose}
      destroyOnClose
      footer={null}
      centered
      width={'80%'}
      style={{ width: '100%', resize: 'none', padding: 'auto', top: 10 }}
    >
      <div>
        <VideoPlayer videoUrl={videoUrl} videoPoster={videoPoster} gaTitle={gaTitle} />
      </div>
    </Modal>
  );
};

export default VideoModal;
