import { createSlice } from '@reduxjs/toolkit';
import config from '../config';

const coreSlice = createSlice({
  name: 'core',
  initialState: {
    title: 'Intra-Cellular Psych Center',
    subtitle: 'A gateway to engaging content from leading experts in mental health',
    dropDownValues: {}
  },
  reducers: {
    setData(state, action) {
      Object.assign(state, action.payload);
    }
  }
});
export const { initialState } = coreSlice;
export const { setData } = coreSlice.actions;

export const getDropDownValues = () => (dispatch, getState) => {
  config
    .fetch(`${config.baseApiUrl}/api/dropdownvalues`, {
      headers: { 'Content-Type': 'application/json' }
    })
    .then((response) => {
      response.json().then((dropDownValues) => {
        dispatch(setData({ dropDownValues: dropDownValues }));
      });
    });
};

export default coreSlice.reducer;
