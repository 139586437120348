/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import * as Sentry from '@sentry/react';
import { hot } from 'react-hot-loader/root';
import { slide as SlideMenu } from 'react-burger-menu';

// import ReactGA from 'react-ga4';

import { Switch, Route, withRouter, Link, Redirect } from 'react-router-dom';
import Auth from './components/Auth';
import DashBoard from './components';
// import UpcomingEvents from './components/Anon/index';
// import Webcasts from './components/Webcasts';
import SlideMenuContext from './context/SlideMenuContext';

import config from './config';

const Router = (props) => {
  const [isSlideMenuOpen, setIsSlideMenuOpen] = useState(false);
  const [isSlideMenuContactRepClicked, setIsSlideMenuContactRepClicked] = useState(false);
  const [isSlideMenuLogoutClicked, setIsSlideMenuLogoutClicked] = useState(false);
  const { store, history, location } = props;
  const { auth } = store.getState();
  const isLoggedIn = auth.user && auth.accessToken;

  const checkUserAuth = async () => {
    const req = await fetch(`${config.baseApiUrl}/auth/checkuserauth`, {
      headers: auth.headers || {}
    });
    console.log('req.status', req.status);
    // const dimension = { dimension1: req.status === 200 ? 1 : 0 };
    // ReactGA.set(dimension);

    // console.log('reactGA', window.location.pathname + window.location.search);
    // debugger;
    // ReactGA.pageview(window.location.pathname + window.location.search, dimension);
  };

  checkUserAuth();

  if (isLoggedIn) {
    Sentry.setUser({ id: auth.user.id, email: auth.user.email });
  }

  if (window.location.pathname === '/rep') {
    // window.location.pathname = '/auth?contactrep=true';
    return <Redirect to="/auth?contactrep=true" />;
  }

  const slideMenuItemHandler = () => {
    setIsSlideMenuOpen(false);
    const rootEl = document.getElementById('root');
    rootEl.scrollTo(0, 0);
  };

  const slideMenuContactRepHAndler = (e) => {
    e.preventDefault();
    setIsSlideMenuContactRepClicked(true);
  };

  const slideMenuLogoutHAndler = (e) => {
    e.preventDefault();
    setIsSlideMenuLogoutClicked(true);
  };

  const isMenuOpen = (state) => {
    console.log('slider open', state.isOpen);
  };

  return (
    <SlideMenuContext.Provider
      value={{
        isSlideMenuOpen,
        setIsSlideMenuOpen,
        isSlideMenuContactRepClicked,
        setIsSlideMenuContactRepClicked,
        setIsSlideMenuLogoutClicked,
        isSlideMenuLogoutClicked
      }}
    >
      <div className={`${window.location.pathname === '/webcasts' ? 'webcasts' : ''}`}>
        <SlideMenu
          right
          customBurgerIcon={false}
          isOpen={isSlideMenuOpen}
          onStateChange={isMenuOpen}
        >
          <Link
            className={`menu-item ${window.location.pathname === '/' ? 'menu-active' : ''}`}
            to="/"
            onClick={slideMenuItemHandler}
          >
            Home
          </Link>
          <Link
            className={`menu-item ${window.location.pathname === '/sessions' ? 'menu-active' : ''}`}
            to="/sessions"
            onClick={slideMenuItemHandler}
          >
            My Sessions
          </Link>
          <Link
            className={`menu-item hide-bm-icon ${
              window.location.pathname === '/profile' ? 'menu-active' : ''
            }`}
            to="/profile"
            onClick={slideMenuItemHandler}
          >
            My Profile
          </Link>
          <Link
            className={`menu-item ${
              window.location.pathname === '/upcoming-events' ? 'menu-active' : ''
            }`}
            to="/upcoming-events"
            onClick={slideMenuItemHandler}
          >
            Upcoming Events
          </Link>

          <a
            tabIndex={0}
            role="button"
            className={'menu-item'}
            onClick={slideMenuContactRepHAndler}
          >
            Contact a Representative
          </a>
          <a
            className="menu-item"
            href="https://itci.mysamplecloset.com/login"
            target="_blank"
            rel="noopener noreferrer"
          >
            Request Samples
          </a>
          <a
            tabIndex={0}
            role="button"
            className="menu-item hide-bm-icon"
            onClick={slideMenuLogoutHAndler}
          >
            Log Out
          </a>
        </SlideMenu>
        <Switch>
          <Route path="/auth" component={Auth} />
          {/* <Route path="/anon/upcoming-events" component={UpcomingEvents} /> */}

          <Route path="/anon/upcoming-events" component={() => <Redirect to="/" />} />
          <Route path="/webcasts" component={() => <Redirect to="/" />} />
          <Route
            path="/"
            render={() => {
              const redirectParams = location.search
                ? `${location.search}&next=${location.pathname}`
                : `?next=${location.pathname}`;
              return isLoggedIn ? <DashBoard /> : history.push(`/auth${redirectParams}`);
            }}
          />
        </Switch>
      </div>
    </SlideMenuContext.Provider>
  );
};

const RouterComponent = withRouter(Router);

export default process.env.NODE_ENV === 'development' ? hot(RouterComponent) : RouterComponent;
