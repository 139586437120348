/* eslint-disable global-require */
import React from 'react';
import { withRouter } from 'react-router-dom';

import { Layout } from 'antd';
import { ITCFooter, ITCHeader, Isi } from './common';
import DashBoard from './DashBoard';
import styles from '../Styles/Content.module.scss';

const { Content } = Layout;

const Main = (props) => {
  const elemRef = React.useRef(null);

  const scrollToTop = () => {
    elemRef.current.scrollIntoView({ block: 'start', behavior: 'smooth' });
  };

  React.useEffect(() => {
    if (elemRef.current) {
      scrollToTop();
    }
  }, [elemRef]);

  return (
    <Layout>
      <ITCHeader scrollToTop={scrollToTop} />
      <Content className={styles.Content}>
        {/* NEED THIS FOR MAIN WRAPPING IF THERES A BG - DONT DELETE */}
        <div className={styles['site-layout-background']} ref={elemRef}>
          {/* NEED THIS FOR MAIN WRAPPING & SCROLLING - DONT DELETE */}
          <div className={styles['site-layout-content']} style={{ minHeight: 380 }}>
            <DashBoard />
            <Isi isDashboard />
          </div>
        </div>
      </Content>
      <ITCFooter />
    </Layout>
  );
};

export default withRouter(Main);
