import React from 'react';
// import { Divider } from 'antd';
import { connect } from 'react-redux';
import { Typography, Divider } from 'antd';

import { SessionCardIcons } from '../common/SessionCardSections';

import { updateData } from '../../redux/authSlice';

import config from '../../config';

const { Title } = Typography;

const SessionComponent = (props) => {
  const [session, setSession] = React.useState(null);
  const [atCapacity, setAtCapacity] = React.useState(false);
  // eslint-disable-next-line no-shadow
  const { code, updateData, setValidSession } = props;

  React.useEffect(() => {
    if (!session) {
      const request = fetch(`${config.baseApiUrl}/api/sessionbycode/${code}`);
      request.then((response) => {
        if (response.status === 404) {
          return;
        }
        if (response.status === 406) {
          setAtCapacity(true);
          return;
        }
        response.json().then((session) => {
          setSession(session);
          setValidSession(true);
          updateData({
            regSessionType: session.meetingtype,
            isVirtualHybrid: session.is_virtual_hybrid
          });
        });
      });
    }
  }, [code, session]); // eslint-disable-line

  if (atCapacity) {
    return (
      <>
        <Title style={{ margin: '10px auto 30px auto', color: '#624b78' }} level={3}>
          Unfortunately this program is at capacity. Please visit the Upcoming Events Page for
          another program that may interest you.
        </Title>
        <Divider />
      </>
    );
  }

  if (!session) {
    return '';
  }

  return (
    <div>
      <SessionCardIcons session={session} preTitle="SESSION" isAuth />
    </div>
  );
};

export default connect(null, { updateData })(SessionComponent);
