import React from 'react';

// create a component
const GreenBox = ({ isAuth }) => {
  return (
    <div
      style={{
        position: 'relative',
        top: isAuth ? 0 : 25,
        background: '#00597d',
        padding: '10px',
        width: '100%',
        color: 'white',
        textAlign: 'center',
        borderBottom: !isAuth && '5px solid #B587B8'
      }}
    >
      Please see full{' '}
      <a
        href="https://www.intracellulartherapies.com/docs/caplyta_pi.pdf"
        target="_BLANK"
        rel="noreferrer noopener"
        style={{ textDecoration: 'underline', color: 'white' }}
      >
        Prescribing Information
      </a>
      , including Boxed Warnings.
    </div>
  );
};

export default GreenBox;
