import React from 'react';

import { Typography } from 'antd';
const { Title, Text } = Typography;

const ITCPolicy = () => {
  return (
    <div
      style={{
        textAlign: 'left',
        maxWidth: 800,
        alignSelf: 'flex-start',
        marginBottom: 20,
        color: '#00597d',
        padding: '0 20px'
      }}
    >
      <Title level={4} style={{ color: '#00597d', textDecoration: 'underline' }}>
        Intra-Cellular Therapies Policy
      </Title>
      <Text style={{ color: '#00597d' }}>
        An individual must not attend a Speaker Program on the same topic more than 2 times in a
        12-month period.
      </Text>
    </div>
  );
};

export default ITCPolicy;
