import React, { useState, useEffect } from 'react';
import moment from 'moment';
import _ from 'lodash';
import { Space, Row, Col, Avatar, Typography } from 'antd';
import { CalendarFilled, ClockCircleFilled, HomeFilled, UserOutlined } from '@ant-design/icons';
import VideoModal from './VideoModal';
import styles from '../../Styles/SessionCard.module.scss';

import { getUrlParams } from '../../config';

const { Title, Text } = Typography;

const SessionCardIcons = (props) => {
  const { session, preTitle, isAuth } = props;
  const [videoModalOpen, setVideoModalOpen] = useState(false);

  const colSize = isAuth ? 24 : 12;

  useEffect(() => {
    const isVideoOpen = getUrlParams('openvideo') === 'true';

    if (isVideoOpen) {
      setVideoModalOpen(true);
    }
  }, []);

  const getMeetingTypeOrLocation = () => {
    const meetingtype = session.is_virtual_hybrid ? 'Virtual / In-Person' : session.meetingtype;

    if (
      _.includes(['1:1', 'In-Office', 'Out-Of-Office'], session.meetingtype) ||
      session.is_virtual_hybrid
    ) {
      const { venue } = session;
      if (venue.name === 'Virtual') {
        return (
          <>
            <HomeFilled className="text--turqoise" /> &nbsp; {meetingtype} ({venue.name})
          </>
        );
      }
      return (
        <>
          <>
            <HomeFilled className="text--turqoise" /> &nbsp; {meetingtype}
          </>
          <br />
          <div style={{ marginLeft: 24 }}>
            {venue.name} <br />
            {venue.address1}, {venue.address2} <br />
            {venue.city}, {venue.state}, {venue.postalcode}
          </div>
        </>
      );
    }
    return (
      <>
        <HomeFilled className="text--turqoise" /> &nbsp; {meetingtype}
      </>
    );
  };

  const colStyle = {
    borderBottom: isAuth && '1px solid rgba(0, 0, 0, 0.3)',
    // width: isAuth && '50%',
    marginTop: isAuth && 2,
    marginBottom: isAuth && 5
  };

  const renderInfoRow = () => {
    if (!isAuth || (isAuth && _.isEmpty(session.video))) {
      return (
        <Row style={{ justifyContent: 'space-between', color: '#00597d' }}>
          <Col xl={colSize} lg={colSize} md={colSize} sm={colSize}>
            <div className="direction-column icons" style={colStyle}>
              <Space direction="vertical">
                <Text style={{ color: '#797A7A' }}>
                  <CalendarFilled className="text--turqoise" /> &nbsp;
                  {moment(session.startdatetime).format('dddd, MMMM D, YYYY')}
                </Text>
                <Text style={{ color: '#797A7A' }}>
                  <ClockCircleFilled className="text--turqoise" /> &nbsp;
                  {moment(session.startdatetime).format('h:mm A')} (
                  {session.timezone.replace(/\s/g, '')})
                </Text>
                <Text style={{ color: '#797A7A' }}>{getMeetingTypeOrLocation()}</Text>
              </Space>
            </div>
          </Col>
          <Col xl={colSize} lg={colSize} md={colSize} sm={colSize}>
            <SessionCardAvatar session={session} cardStyle={colStyle} />
          </Col>
        </Row>
      );
    }

    // layout if the session has a video. this layout has
    // an additional row/column wrapper compared to the above.
    // ugly but it works. maybe we can find a better way to get this done
    return (
      <Row style={{ justifyContent: 'space-evenly' }}>
        <Col xl={16} lg={24} md={24} sm={24}>
          <Row style={{ justifyContent: 'space-between', color: '#00597d', marginRight: 50 }}>
            <Col xl={colSize} lg={colSize} md={colSize} sm={colSize}>
              <div className="direction-column icons" style={colStyle}>
                <Space direction="vertical">
                  <Text style={{ color: '#797A7A' }}>
                    <CalendarFilled className="text--turqoise" /> &nbsp;
                    {moment(session.startdatetime).format('dddd, MMMM D, YYYY')}
                  </Text>
                  <Text style={{ color: '#797A7A' }}>
                    <ClockCircleFilled className="text--turqoise" /> &nbsp;
                    {moment(session.startdatetime).format('h:mm A')} (
                    {session.timezone.replace(/\s/g, '')})
                  </Text>
                  <Text style={{ color: '#797A7A' }}>{getMeetingTypeOrLocation()}</Text>
                </Space>
              </div>
            </Col>
            <Col xl={colSize} lg={colSize} md={colSize} sm={colSize}>
              <SessionCardAvatar session={session} cardStyle={colStyle} />
            </Col>
          </Row>
        </Col>
        <Col
          xl={8}
          lg={24}
          md={24}
          sm={24}
          style={{
            display: 'flex',
            alignItems: 'flex-end'
          }}
        >
          <img
            onClick={() => setVideoModalOpen(true)}
            src={session.video.thumbnail}
            alt="registration page video"
            height={175}
            style={{ cursor: 'pointer', marginTop: 10, objectFit: 'contain' }}
            role="presentation"
          />
          <VideoModal
            open={videoModalOpen}
            stateAction={setVideoModalOpen}
            videoUrl={session.video.url}
            videoPoster={session.video.thumbnail}
            speakerName={session.video.speaker_name}
            meetingTitle={session.video.title_name}
            sessionTitle={session.title}
          />
        </Col>
      </Row>
    );
  };

  return (
    <>
      {preTitle && (
        <Title
          style={{ marginBottom: 0, color: '#624b78' }}
          className={`${styles['title--purple']}`}
          level={4}
        >
          {preTitle}
        </Title>
      )}
      <Title
        style={{ marginTop: 0, color: '#624b78' }}
        className={`${styles['title--purple']}`}
        level={2}
      >
        {session.title}
      </Title>
      {renderInfoRow()}
    </>
  );
};

const SessionCardAvatar = (props) => {
  const { speaker } = props.session;
  // console.log(speaker);
  return (
    <div className="flex" style={props.cardStyle}>
      <Avatar
        shape="square"
        size={70}
        src={speaker.avatar}
        style={{ flexShrink: 0, color: '#624b78' }}
        icon={!speaker.avatar && <UserOutlined />}
      />
      <div style={{ marginLeft: 10, wordBreak: 'keep-all' }}>
        <div
          style={{
            display: 'flex',
            flexWrap: 'nowrap',
            wordBreak: 'keep-all'
          }}
        >
          {speaker.firstname} {speaker.lastname}, {speaker.title}
        </div>
        <div
          style={{
            display: 'flex',
            flexWrap: 'nowrap',
            wordBreak: 'keep-all'
          }}
        >
          {speaker.orgname}
        </div>
        <div
          style={{
            display: 'flex',
            flexWrap: 'nowrap',
            whiteSpace: 'nowrap',
            wordBreak: 'keep-all'
          }}
        >
          {speaker.city}, {speaker.state}
        </div>
      </div>
    </div>
  );
};

export { SessionCardIcons, SessionCardAvatar };
