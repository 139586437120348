import React from 'react';

import { DatePicker, Button } from 'antd';

import config from '../../config';

const { RangePicker } = DatePicker;

const Reports = () => {
  const [dateRange, setDateRange] = React.useState([null, null]);

  const onDateRangeChange = (dates, dateStrings) => {
    console.log('From: ', dates[0], ', to: ', dates[1]);
    console.log('From: ', dateStrings[0], ', to: ', dateStrings[1]);
    setDateRange(dates);
  };

  return (
    <div style={{ height: '100%', margin: 50 }}>
      <h1>Reports</h1>

      <h3>Contact Rep Report</h3>
      <RangePicker onChange={onDateRangeChange} />
      <Button
        type="primary"
        onClick={() => {
          window.open(
            `${config.baseApiUrl}/api/repcontactreport?start_date=${dateRange[0].format(
              'YYYY-MM-DD'
            )}&end_date=${dateRange[1].format('YYYY-MM-DD')}`
          );
        }}
      >
        Download
      </Button>
    </div>
  );
};

export default Reports;
