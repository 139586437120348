import React, { useState, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import _ from 'lodash';
import ReactGA from 'react-ga4';
import { Modal, Form, Input } from 'antd';

import { BorderButton } from '../common';
import RepContactTopics from '../common/RepContactTopics';
import config from '../../config';

const modalStyles = {
  contentStyle: {
    padding: '20px 5px',
    textAlign: 'center',
    justifyContent: 'center',
    fontWeight: 800,
    fontSize: 20,
    color: '#00597D'
  }
};

const requiredRule = {
  required: true,
  message: 'This field is required!'
};

const RepContactModal = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [form] = Form.useForm();
  const [submitted, setSubmitted] = useState(false);
  const [topics, setTopics] = useState([]);

  useEffect(() => {
    if (window.location.search.indexOf('contactrep') > -1) {
      toggleModalOpen();
    }
  }, []); // eslint-disable-line

  const toggleModalOpen = () => {
    setModalOpen(!modalOpen);
    if (submitted) {
      setSubmitted(false);
      setTopics([]);
      form.resetFields();
    }
  };

  const handleTopicSelect = (topicValue) => {
    return (e) => {
      // console.log('topics', topics);
      if (e.target.checked) {
        setTopics([...topics, topicValue]);
      } else {
        setTopics(topics.filter((topic) => topic !== topicValue));
      }
    };
  };

  const submitButtonDisabled = () => {
    const hasErrors = _.flatten(_.map(form.getFieldsError(), 'errors')).length > 0;
    return !topics.length || _.compact(_.values(form.getFieldsValue())).length < 3 || hasErrors;
  };

  const submitContactRequest = () => {
    console.log('submit contact request');
    form
      .validateFields()
      .then((values) => {
        console.log('values', values);
        ReactGA.event({
          category: 'Contact A Representative',
          action: 'Contact A Representative'
        });
        fetch(`${config.baseApiUrl}/api/contactrep`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ ...values, topics })
        }).then(() => {
          setSubmitted(true);
        });
      })
      .catch((errorInfo) => {
        console.log('errorInfo', errorInfo);
      });
  };

  const renderModalContent = () => {
    if (submitted) {
      return (
        <div style={modalStyles.contentStyle}>
          Thank you, your request has been sent.
          <br />
          An Intra-Cellular Thearapies Representative will be in contact with you soon.
          <br />
          <br />
          While you&apos;re here, we invite you to register now for the Intra-Cellular Therapies
          Psych Center for access to Intra-Cellular Therapies speaker, programs, CAPLYTA content,
          and more!
          <div className="flex horizontal-center" style={{ marginTop: 25 }}>
            <BorderButton
              wrapperStyle={{ width: 300 }}
              onClick={toggleModalOpen}
              text="CLOSE WINDOW"
            />
          </div>
        </div>
      );
    }
    return (
      <div style={modalStyles.contentStyle}>
        We greatly appreciate your interest in connecting with an Intra-Cellular Therapies
        representative. In order to make the best use of your time, please select from the following
        topics for appropriate follow-up <span style={{ textDecoration: 'underline' }}>and</span>{' '}
        provide your contact information below:
        <RepContactTopics handleTopicSelect={handleTopicSelect} topics={topics} />
        <Form
          form={form}
          layout="vertical"
          name="contactrep"
          style={{ marginTop: 20 }}
          onFinish={submitContactRequest}
        >
          <div className="flex mobileVertical">
            <Form.Item
              style={{ width: '50%', marginRight: 10 }}
              label="First and Last Name*:"
              name="first_last"
              rules={[requiredRule]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              style={{ width: '50%' }}
              name="zip_code"
              label="Zip Code*:"
              rules={[
                requiredRule,
                {
                  validator: (rule, value) => {
                    if (
                      (value && value.length < 5) ||
                      (value && value.length > 10) ||
                      (value && !_.isNumber(parseInt(value.trim(), 10)))
                    ) {
                      // eslint-disable-next-line prefer-promise-reject-errors
                      return Promise.reject('Enter 5 digits.');
                    }
                    return Promise.resolve();
                  }
                }
              ]}
            >
              <Input />
            </Form.Item>
          </div>
          <Form.Item
            label="Email Address*:"
            name="email"
            rules={[requiredRule, { type: 'email', message: 'Not a valid email' }]}
          >
            <Input />
          </Form.Item>
          Please click &quot;Confirm&quot; to send your inquiry to an Intra-Cellular Therapies
          representative.
          <div
            className="flex horizontal-center vertical-center flex-column"
            style={{ marginTop: 20, height: 100, justifyContent: 'space-evenly' }}
          >
            <Form.Item shouldUpdate>
              {() => (
                <BorderButton
                  htmlType="submit"
                  disabled={submitButtonDisabled()}
                  wrapperStyle={{ width: 300 }}
                  text={'CONFIRM'}
                />
              )}
            </Form.Item>
            <BorderButton
              style={{ marginTop: 20 }}
              onClick={toggleModalOpen}
              wrapperStyle={{ width: 300 }}
              text="CANCEL"
            />
          </div>
        </Form>
      </div>
    );
  };

  console.log('repmodalopen', modalOpen);

  return (
    <Modal
      visible={modalOpen}
      width={800}
      style={{ padding: isMobile ? null : 50 }}
      onClose={toggleModalOpen}
      onCancel={toggleModalOpen}
      maskClosable={false}
      destroyOnClose
      footer={null}
    >
      {renderModalContent()}
    </Modal>
  );
};

export default RepContactModal;
