// import { isImmutableDefault } from '@reduxjs/toolkit';
import React, { useState, useEffect } from 'react';
import VizSensor from 'react-visibility-sensor';
import { isDesktop } from 'react-device-detect';
import { UpOutlined } from '@ant-design/icons';
import ReactGA from 'react-ga4';
import styles from '../../Styles/Isi.module.scss';
import { useWindowSize } from './CustomHooks';
import ITCFooter from './ITCFooter';

const Isi = (props) => {
  const [imgViz, setImgViz] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [expandedISIHeight, setExpandedISIHeight] = useState(0);
  const windowSize = useWindowSize();

  useEffect(() => {
    const headerHeight = document.getElementById('itc-header');
    let computedExpandedISIHeight = windowSize.height - headerHeight.offsetHeight;
    if (props.isDashboard) {
      computedExpandedISIHeight -= 25;
    }
    setExpandedISIHeight(computedExpandedISIHeight);
  }, [windowSize]); // eslint-disable-line

  const toggleIsi = () => {
    ReactGA.event({
      category: 'ISI',
      action: isCollapsed ? 'Expanded' : 'Collapsed'
    });
    setIsCollapsed(!isCollapsed);
  };

  const getTop = () => {
    return (
      <div className={styles.text} style={{ paddingTop: 20 }}>
        <div>
          CAPLYTA
          <sup>
            <span>&reg;</span>
          </sup>{' '}
          (lumateperone) is indicated in adults for the treatment of schizophrenia and depressive
          episodes associated with bipolar I or II disorder (bipolar depression), as monotherapy and
          as adjunctive therapy with lithium or valproate.
        </div>
        <div style={{ fontFamily: 'Roboto-Bold' }} className={styles.header}>
          Important Safety Information
        </div>
        {(!isCollapsed || isDesktop) && (
          <>
            <div style={{ fontFamily: 'Roboto-Bold' }}>Boxed Warnings:</div>
            <div>
              <table>
                <tbody>
                  <tr>
                    <td style={{ width: 8 }}> </td>
                    <td className={`${styles['isi-bold']}`}>
                      Elderly patients with dementia-related psychosis treated with antipsychotic
                      drugs are at an increased risk of death. CAPLYTA is not approved for the
                      treatment of patients with dementia-related psychosis.
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: 8 }}> </td>
                    <td className={`${styles['isi-bold']}`}>
                      Antidepressants increased the risk of suicidal thoughts and behaviors in
                      pediatric and young adults in short-term studies. Closely monitor all
                      antidepressant-treated patients for clinical worsening, and for emergence of
                      suicidal thoughts and behaviors. The safety and effectiveness of CAPLYTA have
                      not been established in pediatric patients.
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div>
              <span style={{ fontFamily: 'Roboto-Bold' }}>Contraindications:</span> CAPLYTA is
              contraindicated in patients with known hypersensitivity to lumateperone or any
              components of CAPLYTA. Reactions have included pruritus, rash (e.g., allergic
              dermatitis, papular rash, and generalized rash), and urticaria.
            </div>

            {!isCollapsed && getBottom()}
          </>
        )}
      </div>
    );
  };

  const getBottom = () => {
    return (
      <>
        {!isDesktop && (
          <>
            <div style={{ fontFamily: 'Roboto-Bold' }}>Boxed Warnings:</div>
            <div>
              <table>
                <tbody>
                  <tr>
                    <td style={{ width: 8 }}> </td>
                    <td className={`${styles['isi-bold']}`}>
                      Elderly patients with dementia-related psychosis treated with antipsychotic
                      drugs are at an increased risk of death. CAPLYTA is not approved for the
                      treatment of patients with dementia-related psychosis.
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: 8 }}> </td>
                    <td className={`${styles['isi-bold']}`}>
                      Antidepressants increased the risk of suicidal thoughts and behaviors in
                      pediatric and young adults in short-term studies. Closely monitor all
                      antidepressant-treated patients for clinical worsening, and for emergence of
                      suicidal thoughts and behaviors. The safety and effectiveness of CAPLYTA have
                      not been established in pediatric patients.
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div>
              <span style={{ fontFamily: 'Roboto-Bold' }}>Contraindications:</span> CAPLYTA is
              contraindicated in patients with known hypersensitivity to lumateperone or any
              components of CAPLYTA. Reactions have included pruritus, rash (e.g., allergic
              dermatitis, papular rash, and generalized rash), and urticaria.
            </div>
          </>
        )}
        <div className={styles.text}>
          <div>
            <span style={{ fontFamily: 'Roboto-Bold' }}>Warnings &amp; Precautions:</span>{' '}
            Antipsychotic drugs have been reported to cause:
          </div>
          <ul className={`${styles['isi-ul']}`}>
            <li>
              <span className={`${styles['isi-bold']}`}>
                Cerebrovascular Adverse Reactions in Elderly Patients with Dementia-Related
                Psychosis
              </span>
              , including stroke and transient ischemic attack. See Boxed Warning above.
            </li>
            <li>
              <span className={`${styles['isi-bold']}`}>Neuroleptic Malignant Syndrome</span>, which
              is a potentially fatal reaction. Signs and symptoms include hyperpyrexia, muscle
              rigidity, delirium, autonomic instability, elevated creatinine phosphokinase,
              myoglobinuria (and/or rhabdomyolysis), and acute renal failure. Manage with immediate
              discontinuation of CAPLYTA and provide intensive symptomatic treatment and monitoring.
            </li>
            <li>
              <span className={`${styles['isi-bold']}`}>Tardive Dyskinesia (TD)</span>, a syndrome
              of potentially irreversible, dyskinetic, and involuntary movements which may increase
              as the duration of treatment and total cumulative dose increases. The syndrome can
              develop after a relatively brief treatment period, even at low doses, or after
              treatment discontinuation. Given these considerations, CAPLYTA should be prescribed in
              a manner most likely to reduce the risk of TD. Discontinue CAPLYTA if clinically
              appropriate.
            </li>
            <li>
              <span className={`${styles['isi-bold']}`}>Metabolic Changes</span>, including
              hyperglycemia, diabetes mellitus, dyslipidemia, and weight gain. Hyperglycemia, in
              some cases extreme and associated with ketoacidosis, hyperosmolar coma or death, has
              been reported in patients treated with antipsychotics. Measure weight and assess
              fasting plasma glucose and lipids when initiating CAPLYTA and monitor periodically
              during long-term treatment.
            </li>
            <li>
              <span className={`${styles['isi-bold']}`}>
                Leukopenia, Neutropenia, and Agranulocytosis (including fatal cases)
              </span>
              . Perform complete blood counts in patients with pre-existing low white blood cell
              count (WBC) or history of leukopenia or neutropenia. Discontinue CAPLYTA if clinically
              significant decline in WBC occurs in absence of other causative factors.
            </li>
            <li>
              <span className={`${styles['isi-bold']}`}>Orthostatic Hypotension and Syncope</span>.
              Monitor heart rate and blood pressure and warn patients with known cardiovascular or
              cerebrovascular disease. Orthostatic vital signs should be monitored in patients who
              are vulnerable to hypotension.
            </li>
            <li>
              <span className={`${styles['isi-bold']}`}>Falls</span>. CAPLYTA may cause somnolence,
              postural hypotension, and motor and/or sensory instability, which may lead to falls
              and, consequently, fractures and other injuries. Assess patients for risk when using
              CAPLYTA.
            </li>
            <li>
              <span className={`${styles['isi-bold']}`}>Seizures</span>. Use CAPLYTA cautiously in
              patients with a history of seizures or with conditions that lower seizure threshold.
            </li>
            <li>
              <span className={`${styles['isi-bold']}`}>
                Potential for Cognitive and Motor Impairment
              </span>
              . Advise patients to use caution when operating machinery or motor vehicles until they
              know how CAPLYTA affects them.
            </li>
            <li>
              <span className={`${styles['isi-bold']}`}>Body Temperature Dysregulation</span>. Use
              CAPLYTA with caution in patients who may experience conditions that may increase core
              body temperature such as strenuous exercise, extreme heat, dehydration, or concomitant
              anticholinergics.
            </li>
            <li>
              <span className={`${styles['isi-bold']}`}>Dysphagia</span>. Use CAPLYTA with caution
              in patients at risk for aspiration.
            </li>
          </ul>

          <div style={{ marginBottom: 10 }}>
            <span style={{ fontFamily: 'Roboto-Bold' }}>Drug Interactions:</span> Avoid concomitant
            use with CYP3A4 inducers. Reduce dose for concomitant use with strong CYP3A4 inhibitors
            (10.5 mg) or moderate CYP3A4 inhibitors (21 mg).
          </div>
          <div style={{ marginBottom: 10 }}>
            <span style={{ fontFamily: 'Roboto-Bold' }}>Special Populations:</span> Neonates exposed
            to antipsychotic drugs during the third trimester of pregnancy are at risk for
            extrapyramidal and/or withdrawal symptoms following delivery. Reduce dose for patients
            with moderate or severe hepatic impairment (21 mg).
          </div>
          <div style={{ marginBottom: 30 }}>
            <span style={{ fontFamily: 'Roboto-Bold' }}>Adverse Reactions:</span> The most common
            adverse reactions in clinical trials with CAPLYTA vs placebo were:
            <table style={{ marginTop: 5 }}>
              <tbody>
                <tr>
                  <td style={{ width: 8 }}> </td>
                  <td>Schizophrenia: somnolence/sedation (24% vs 10%) and dry mouth (6% vs 2%).</td>
                </tr>
                <tr>
                  <td style={{ width: 8 }}> </td>
                  <td>
                    Bipolar Depression (Monotherapy, Adjunctive therapy): somnolence/sedation (13%
                    vs 3%, 13% vs 3%), dizziness (8% vs 4%, 11% vs 2%), nausea (8% vs 3%, 9% vs 4%),
                    and dry mouth (5% vs 1%, 5% vs 1%).
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div style={{ marginTop: 10, marginBottom: 10 }}>
            CAPLYTA is availiable in 10.5 mg, 21 mg, and 42 mg capsules.
          </div>

          <div>
            Please see{' '}
            <a
              href="https://www.intracellulartherapies.com/docs/caplyta_pi.pdf"
              target="_blank"
              rel="noopener noreferrer"
              className="underline"
              style={{ color: '#00597d' }}
            >
              Prescribing Information
            </a>
            ,&nbsp;including&nbsp;
            <span>Boxed Warnings</span>.
          </div>

          <div>
            Please{' '}
            <a
              className="underline"
              style={{ color: '#00597d' }}
              href="https://www.caplytahcp.com/schizophrenia/disclosure-information"
              target="_blank"
              rel="noopener noreferrer"
            >
              click here
            </a>{' '}
            for State WAC disclosure and pricing transparency information.
          </div>
        </div>
        {!isCollapsed && (
          <div style={{ marginTop: 60 }}>
            <ITCFooter />
          </div>
        )}
      </>
    );
  };

  const onChange = (isVisible) => {
    setImgViz(isVisible);
  };

  // console.log(styles);

  return (
    <VizSensor className={styles.shadow} onChange={onChange} partialVisibility>
      <section className={styles.isi}>
        <div style={{ position: 'relative' }}>
          <div className={`wrapper ${styles['isi-wrapper']}`}>
            <div
              onClick={toggleIsi}
              className={`isi-backup ${styles['isi-preview']} ${
                props.higherPreview && isCollapsed ? styles['isi-higher-preview'] : ''
              } ${imgViz ? styles.hide : ''} ${isCollapsed && !imgViz ? '' : 'uncollapsed'}`}
              style={{
                zIndex: 2147483647,
                height: !isCollapsed ? expandedISIHeight : '',
                overflow: !isCollapsed ? 'scroll' : 'inherit'
              }}
              role="presentation"
            >
              <UpOutlined
                className={`${styles['isi-arrow']}`}
                style={{
                  transform: `rotate(${!isCollapsed ? 180 : 0}deg)`
                }}
              />
              {getTop()}
            </div>
            {getTop()}
            {getBottom()}
          </div>
        </div>
      </section>
    </VizSensor>
  );
};

export default Isi;
