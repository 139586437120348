/* eslint-disable indent */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Modal, Typography, Row, Col } from 'antd';
import ReactGA from 'react-ga4';
import config from '../../config';
import { ITCCheckbox } from './ITCInputs';
// import { styles } from '../../Styles/InterestTopics.module.scss';

const { Text, Title } = Typography;

const InterestTopics = (props) => {
  const { auth } = props;
  const [modal, modalContext] = Modal.useModal();
  // const [checked, setChecked] = useState(false);
  const [iTopicsData, setITopicsData] = useState({
    myInterestTopics: [],
    interestTopics: []
  });

  // useeffect to get topics
  useEffect(() => {
    if (!iTopicsData.interestTopics?.length) {
      config
        .fetch(`${config.baseApiUrl}/api/interesttopics`, {
          headers: auth.headers
        })
        .then((response) => {
          response.json().then((interestTopicsData) => {
            setITopicsData({ ...interestTopicsData });
          });
        });
    }
  }, [auth, iTopicsData]);

  const toggleInterestTopics = (interestTopic) => {
    const wasInterested = _.includes(iTopicsData.myInterestTopics, interestTopic.id);
    ReactGA.event({
      category: 'My Interests',
      action: `${interestTopic.name} ${wasInterested ? 'Removed' : 'Added'}`
    });
    const prevState = { ...iTopicsData };
    // console.log('wasInterested', wasInterested);

    const newInterests = !wasInterested
      ? { myInterestTopics: iTopicsData.myInterestTopics.concat([interestTopic.id]) }
      : {
          myInterestTopics: _.filter(
            iTopicsData.myInterestTopics,
            (iTopicId) => iTopicId !== interestTopic.id
          )
        };
    // console.log('newData', newInterests);
    setITopicsData({ ...iTopicsData, ...newInterests });

    config
      .fetch(`${config.baseApiUrl}/api/interesttopics`, {
        headers: auth.headers,
        method: 'PUT',
        body: JSON.stringify({
          interestTopic: interestTopic.id,
          action: wasInterested ? 'remove' : 'add'
        })
      })
      .then((response) => {
        if (response.status !== 200) {
          setITopicsData(prevState);
          modal.error({
            title: 'Something went wrong!',
            content: <p>Your action was reset.</p>,
            icon: null,
            maskClosable: true,
            okText: 'CLOSE',
            centered: true,
            okButtonProps: {
              style: {
                justifyContent: 'center',
                width: 300,
                fontSize: 16,
                fontWeight: 600,
                borderRadius: '16px',
                backgroundColor: '#00597D'
              }
            }
          });
        }
      });
  };

  // const selectRadioButton = () => {
  //   setChecked(true);
  // };

  // const deselectRadioButton = () => {
  //   setChecked(false);
  // };

  // const handleChange = () => {
  //   if (checked) {
  //     deselectRadioButton();
  //   } else {
  //     selectRadioButton();
  //   }
  // };

  return (
    <div className={'interest-topics-bg'}>
      <div
        className={'interest-topics flex'}
        style={{
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          maxWidth: 1000,
          margin: '0px auto',
          padding: '90px 0px 80px 0',
          backgroundSize: 'cover !important'
        }}
      >
        <Title style={{ color: '#04799e', textAlign: 'center', marginBottom: '6px' }} level={2}>
          My Interests
        </Title>
        <Text
          style={{
            textAlign: 'center',
            color: '#04799e'
          }}
        >
          Check the boxes next to your interests, and we’ll provide information as it becomes
          available. <br />
          Need information now? Click on the options for materials that are readily available. Thank
          you!
        </Text>
        <div
          className="flex column-wrap"
          style={{
            justifyContent: 'space-around',
            flexWrap: 'wrap',
            margin: '10px auto',
            width: '100%'
          }}
        >
          <Row gutter={[20, 20]} style={{ width: '100%' }} className="my-interests-row">
            {iTopicsData.interestTopics &&
              iTopicsData.interestTopics.map((iTopic) => {
                const isChecked = _.includes(iTopicsData.myInterestTopics, iTopic.id);
                return (
                  <Col key={iTopic.id} span={8}>
                    <ITCCheckbox
                      buttonStyle={{ display: 'block', padding: 0 }}
                      topic={iTopic}
                      key={iTopic.id}
                      onChange={() => toggleInterestTopics(iTopic)}
                      id={iTopic.id}
                      isChecked={isChecked}
                    />
                  </Col>
                );
              })}
          </Row>
        </div>
        {modalContext}
      </div>
    </div>
  );
};

const mapStateToProps = ({ auth }) => {
  return { auth };
};

export default connect(mapStateToProps, null)(InterestTopics);
