import React from 'react';
import { connect } from 'react-redux';
import { Form, Input, Typography, Space } from 'antd';
import { EditFilled } from '@ant-design/icons';
import ReactGA from 'react-ga4';
import { RegisterAndProfileForm, BorderButton } from '../common';
import { updateData, updateEmail, updatePassword } from '../../redux/authSlice';
import styles from '../../Styles/Profile.module.scss';
import config from '../../config';

const { Link, Title } = Typography;

const Styles = {
  labelStyle: { marginBottom: 5 },
  inputStyle: { color: '#5b5b5b', marginBottom: 10 },
  tailLayout: {
    wrapperCol: {
      offset: 0,
      span: 24
    }
  }
};

const Profile = (props) => {
  const [form] = Form.useForm();
  const [error, setError] = React.useState(false);
  const [profileFormActive, setProfileFormActive] = React.useState(false);
  const [emailEditOpen, setEmailEditOpen] = React.useState(false);
  const [emailSubmitDisabled, setEmailSubmitDisabled] = React.useState(true);
  // const [pwdlEditOpen, setPwdEditOpen] = React.useState(false);
  // const [pwdSubmitDisabled, setPwdSubmitDisabled] = React.useState(true);
  const [submitting, setSubmitting] = React.useState(false);

  const { user, headers, updateData } = props;

  const onProfileFormFinish = (values) => {
    // console.log('Success:', values);
    setSubmitting(true);
    fetch(`${config.baseApiUrl}/auth/user/${user.id}`, {
      method: 'PUT',
      headers: headers,
      body: JSON.stringify(values)
    }).then((response) => {
      if (response.status !== 200) {
        // console.log('err on profile update.... ');
        setError(true);
        setSubmitting(false);
        return;
      }
      response.json().then((userData) => {
        updateData({ user: userData });
        setError(false);
        setProfileFormActive(false);
        setSubmitting(false);
      });
    });
  };

  const toggleEmailEditOpen = () => {
    ReactGA.event({
      category: 'Profile',
      action: !emailEditOpen ? 'Email Edit Opened' : 'Email Edit Closed'
    });
    setEmailEditOpen(!emailEditOpen);
    // setPwdEditOpen(false);
  };

  // const togglePwdEditOpen = () => {
  //   ReactGA.event({
  //     category: 'Profile',
  //     action: !pwdlEditOpen ? 'Password Edit Opened' : 'Password Edit Closed'
  //   });
  //   setPwdEditOpen(!pwdlEditOpen);
  //   setEmailEditOpen(false);
  // };

  const onEmailFormFinish = (values) => {
    // console.log('Success:', values);
    props.updateEmail(values.email1, values.password);
    toggleEmailEditOpen();
  };

  const onEmailFormFinishFailed = (errorInfo) => {
    // console.log('onEmailFormFinishFailed Failed:', errorInfo);
  };

  // const onPasswordFormFinish = (values) => {
  //   console.log('Success:', values);
  //   props.updatePassword(values.password1, values.password);
  //   togglePwdEditOpen();
  // };

  // const onPasswordFormFinishFailed = (errorInfo) => {
  //   console.log('onEmailFormFinishFailed Failed:', errorInfo);
  // };

  const editToggle = (formToggle) => {
    return (
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 10 }}>
        <Link onClick={formToggle}>
          Edit <EditFilled />
        </Link>
      </div>
    );
  };

  const renderEmailForm = () => {
    if (!emailEditOpen) {
      return (
        <div
          style={{
            maxWidth: 300,
            margin: '0 auto',
            padding: 20,
            marginTop: 50,
            border: '1px solid',
            borderRadius: 5
          }}
        >
          {editToggle(toggleEmailEditOpen)}
          <Input disabled autoComplete="off" defaultValue={user.email} style={Styles.inputStyle} />
        </div>
      );
    }

    return (
      <div
        style={{
          backgroundColor: '#E2EBF0',
          padding: 10,
          marginBottom: 10,
          borderRadius: 20,
          width: '80%',
          margin: '0 auto'
        }}
      >
        {editToggle(toggleEmailEditOpen)}
        <Form
          layout="vertical"
          name="email_form"
          onFinish={onEmailFormFinish}
          onFinishFailed={onEmailFormFinishFailed}
          requiredMark={false}
        >
          <Form.Item
            label="New Email"
            name="email1"
            rules={[
              {
                required: true,
                message: 'Please input your new Email.'
              }
            ]}
          >
            <Input autoComplete="chrome-off" />
          </Form.Item>
          <Form.Item
            label="Confirm Email"
            name="email2"
            dependencies={['email1']}
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Please confirm your Email.'
              },
              {
                type: 'email',
                message: 'The entered value is not a valid email address.'
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (value && value.trim().length && getFieldValue('email1') === value) {
                    setEmailSubmitDisabled(false);
                    return Promise.resolve();
                  }
                  setEmailSubmitDisabled(true);
                  // eslint-disable-next-line prefer-promise-reject-errors
                  return Promise.reject('The two emails that you entered do not match!');
                }
              })
            ]}
          >
            <Input autoComplete="chrome-off" />
          </Form.Item>
          <Form.Item {...Styles.tailLayout}>
            <BorderButton text={'SAVE'} htmlType="submit" disabled={emailSubmitDisabled} />
          </Form.Item>
        </Form>
      </div>
    );
  };

  // renderPasswordForm = () => {
  //     const label = emailEditOpen ? 'Current Password' : 'Password';

  //     if (!pwdlEditOpen) {
  //       return (
  //         <div>
  //           {editToggle(label, togglePwdEditOpen)}
  //           <Input
  //             disabled
  //             autoComplete="off"
  //             defaultValue={'***********'}
  //             style={Styles.inputStyle}
  //           />
  //         </div>
  //       );
  //     }

  //     return (
  //       <div style={{ backgroundColor: '#bcbcbc', padding: 10, marginBottom: 10 }}>
  //         {editToggle(label, togglePwdEditOpen)}
  //         <Form
  //           layout="vertical"
  //           name="password_form"
  //           onFinish={onPasswordFormFinish}
  //           onFinishFailed={onPasswordFormFinishFailed}
  //         >
  //           <Form.Item name="password">  const
  //             <Input.Password
  //               autoComplete="off"
  //               rules={[
  //                 {
  //                   required: true,
  //                   message: 'Please input your password!'
  //                 }
  //               ]}
  //             />
  //           </Form.Item>

  //           <Form.Item
  //             label="New Password"
  //             name="password1"
  //             rules={[
  //               {
  //                 required: true,
  //                 message: 'Please input your new password!'
  //               },
  //               {
  //                 validator: (rule, value) => {
  //                   if (value.length < 8 || !/[A-Z]/.test(value) || !/[0-9]/.test(value)) {
  //                     return Promise.reject(
  //                       'Passwords must have at least 8 characters, contain at least one uppercase letter, and at least one number'
  //                     );
  //                   }
  //                   return Promise.resolve();
  //                 }
  //               }
  //             ]}
  //           >
  //             <Input.Password autoComplete="off" />
  //           </Form.Item>

  //           <Form.Item
  //             label="Confirm Password"
  //             name="password2"
  //             dependencies={['password1']}
  //             hasFeedback
  //             rules={[
  //               {
  //                 required: true,
  //                 message: 'Please confirm your new Password!'
  //               },
  //               ({ getFieldValue }) => ({
  //                 validator(rule, value) {
  //                   if (!value || getFieldValue('password1') === value) {
  //                     setPwdSubmitDisabled(false);
  //                     return Promise.resolve();
  //                   }
  //                   setPwdSubmitDisabled(true);
  //                   return Promise.reject('The two passwords you entered do not match!');
  //                 }
  //               })
  //             ]}
  //           >
  //             <Input.Password />
  //           </Form.Item>
  //           <Form.Item {...Styles.tailLayout}>
  //             <BorderButton text={'Change Password'} htmlType="submit" disabled={pwdSubmitDisabled} />
  //           </Form.Item>
  //         </Form>
  //       </div>
  //     );
  //   };

  return (
    <>
      <div className={`${styles['bg-layer']} ${styles.primary}`}>
        <div className={`${styles.wrapper}`}>
          <Title style={{ fontSize: 30 }} className={`${styles['title--white']}`} level={2}>
            Intra-Cellular Therapies Psych Center
          </Title>
          <Title style={{ fontSize: 50 }} className={`${styles['title--white']}`} level={1}>
            My Profile
          </Title>
        </div>
      </div>
      <div className={`${styles.wrapper}`}>
        <Space direction="vertical" style={{ width: '100%' }}>
          <RegisterAndProfileForm
            formType={'profile'}
            user={user}
            form={form}
            onFormFinish={onProfileFormFinish}
            profileFormActive={profileFormActive}
            setProfileFormActive={setProfileFormActive}
            error={error}
            submitting={submitting}
          />

          {renderEmailForm()}
          {/* {renderPasswordForm()} */}
        </Space>
      </div>
    </>
  );
};

const mapStateToProps = ({ auth }) => {
  const { user, loading, headers } = auth;
  return { user, loading, headers };
};

export default connect(mapStateToProps, { updateEmail, updatePassword, updateData })(Profile);
