export const gtagConversion = (send_to) => {
  try {
    window.gtag('event', 'conversion', {
      allow_custom_scripts: true,
      session_id: sessionStorage.getItem('sessionID'),
      send_to
    });
  } catch (e) {
    console.log(e);
  }
};
