import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import config from '../../config';

const ContactAndSamples = (props) => {
  const [loading, setLoading] = React.useState(true);
  // const [contactRepModalType, setContactRepModalType] = React.useState('topicSelect');

  const { requestType, externalId } = props.match.params;

  if (externalId === '0') {
    props.history.push(`/auth/register${props.location.search}`);
  }

  React.useEffect(() => {
    // console.log('call api to request samples/contact rep', requestType, externalId);
    // path('requestsamples', views.RequestSamplesView.as_view()),
    //   path('contactrep', views.ContactRepView.as_view()),
    fetch(`${config.baseApiUrl}/api/${requestType}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ external_id: externalId })
    }).then((response) => {
      if (response.status !== 201) {
        return props.history.push(
          `/auth/${response.status === 404 ? 'register' : 'login'}${props.location.search}`
        );
      }
      setLoading(false);
    });
  }, [requestType, externalId]); // eslint-disable-line

  if (loading) {
    return (
      <Spin size="large" indicator={<LoadingOutlined style={{ color: '#624B78' }} spinning />} />
    );
  }

  if (requestType === 'requestsamples') {
    return (
      <div>
        Thank you, your request has been received. A Sales Representative will follow up with you
        regarding information about Nurtec ODT and to provide requested samples.
        <br />
        <br />
        Thank you again for your interest and commitment. <br />
        <br />
        Best,
        <br />
        The Intra-Cellular Therapies Team
      </div>
    );
  }
  return (
    <div>
      Thank you, your request has been received. A Representative will be in contact with you.
      <br />
      <br />
      Best,
      <br />
      The Intra-Cellular Therapies Team
    </div>
  );
};

export default ContactAndSamples;
